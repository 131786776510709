import React, { useState, useRef, useEffect } from "react";
import "../assets/css/contact.css";
import UtilsHelmet from "../utils/helmet";
import CryptoJS from "crypto-js";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { setMetaDescription } from "../utils/setMetaDescription";

const ContactComponent = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { data } = useSelector((state) => state.language);
  const language = useSelector((state) => state.language.language);
  useEffect(() => {
    setMetaDescription(
      "Bize ulaşmak için iletişim formunu doldurun veya iletişim bilgilerini kullanarak direkt olarak bizimle iletişime geçin."
    );
  }, []);
  const form = useRef();
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isButtonDisabled) {
      setIsButtonDisabled(true);

      const encryptedText = CryptoJS.AES.encrypt(
        uuidv4(),
        process.env.REACT_APP_SSK
      ).toString();

      const FD = new FormData(form.current);
      FD.append("accessToken", encryptedText);

      const obj = {
        message: FD.get("message"),
        userInfo: FD.get("userInfo"),
        from: FD.get("from"),
        subject: FD.get("subject"),
        tel: FD.get("tel"),
        ownerName: "osiwebmail@gmail.com",
        to: "busedbilek@gmail.com",
        host: "smtp.gmail.com",
        service: "gmail",
        accessToken: encryptedText,
      };
      try {
        const res = await axios.post("https://nodemailer.3wweb.org/", obj, {
          auth: {
            username: "OSICrew_Node_Mailler",
            password: "1786oSi?173",
          },
        });
        if (res) {
          setIsSuccess(true);
          toast.success(data.mesaj_gonderildi, { autoClose: 4500 });
        }
      } catch (error) {
        toast.error(error.message, { autoClose: 4500 });
      }

      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 3000);
    }
  };
  return (
    <>
      <UtilsHelmet
        title="İletişim"
        canonical={`https://www.perfumeatelier.com.tr/${language}/iletisim`}
      />
      <div className="contact-page main-space ">
        <div className="row">
          <div className="">
            <h1>
              <b> {data.iletisim}</b>
            </h1>
            <div className="d-lg-flex mt-5">
              <form
                className="col-lg-6 col-12"
                ref={form}
                onSubmit={handleSubmit}
              >
                <div className="d-lg-flex ">
                  <div className="d-grid col-lg-5">
                    <label>
                      {data.isim}/{data.soyisim}
                    </label>
                    <input
                      type="text"
                      name="userInfo"
                      placeholder={data.isim + "/" + data.soyisim}
                    ></input>
                  </div>
                  <div className="d-grid col-lg-5 offset-lg-1">
                    <label>E-Mail</label>
                    <input
                      type="text"
                      name="from"
                      placeholder="E-Posta"
                    ></input>
                  </div>
                </div>
                <div className="d-lg-flex  mt-2">
                  <div className="d-grid col-lg-5">
                    <label> {data.telefon}</label>
                    <input
                      type="text"
                      name="tel"
                      placeholder={data.telefon}
                      maxLength={11}
                    ></input>
                  </div>
                  <div className="d-grid col-lg-5 offset-lg-1">
                    <label> {data.konu}</label>
                    <input
                      type="text"
                      name="subject"
                      placeholder={data.konu}
                    ></input>
                  </div>
                </div>
                <div className="d-grid col-lg-11 col-12 mt-2">
                  <label> {data.mesaj}</label>
                  <textarea
                    rows={5}
                    name="message"
                    placeholder={data.mesaj}
                  ></textarea>
                </div>
                <button
                  className="button-type-one mt-3"
                  id="send_btn"
                  disabled={isButtonDisabled}
                >
                  {data.gonder}
                </button>
              </form>
              <div className="col-lg-6 col-md-6 col-sm-6 mt-lg-0 mt-md-3 mt-3">
                <h2> {data.bize_ulasin}</h2>
                <p> {data.adres}</p>
                <a href="https://www.google.com/maps/dir//Ba%C4%9Fl%C4%B1ca,+Yeni,+1015.Sokak+No:6%2F1,+06790+Etimesgut%2FAnkara/@39.9101159,32.5583437,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x14d33902f0009903:0x3f1b78ae33bc5458!2m2!1d32.6407446!2d39.9101451?hl=tr&entry=ttu">
                  <p>
                    YENİ BAĞLICA MAHALLESİ 1015. SOKAK NO:6 ETİMESGUT/ANKARA
                  </p>
                </a>

                <br />
                <p>E-POSTA:</p>
                <a
                  href="mailto:info@perfumeatelier.com.tr"
                  aria-label="perfume mail"
                >
                  <p>info@perfumeatelier.com.tr</p>
                </a>
                <br />
                <p> {data.telefon_upper}</p>
                <div className="d-flex">
                  <a href="tel:+903125434455" aria-label="perfume phone">
                    <p>0312 543 44 55</p>
                  </a>
                  &nbsp;-&nbsp;
                  <a href="tel:+903125434433" aria-label="perfume phone">
                    <p>0312 543 44 33</p>
                  </a>
                </div>

                <br />

                <p>WHATSAPP:</p>
                <a href="https://api.whatsapp.com/send?phone=+905300121212">
                  <p>0530 012 12 12 </p>
                </a>
                <br />
                <p> {data.pazartesi_cuma} 09:00-18:00</p>
                <p> {data.cumartesi} 09:00-15:00</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactComponent;
