import React from "react";
import noImage from "../assets/img/no_pic.webp";
import { Link } from "react-router-dom";
import { removeTurkishChars } from "../utils/urlUtils";
import FavoriteButton from "../utils/FavoriteButton";
import AddBasketButton from "../utils/AddBasketButton";
import { useSelector } from "react-redux";
import "../assets/css/products.css";

const { REACT_APP_BASE_URL } = process.env;

const Bestseller = () => {
  const products = useSelector((state) => state.bestSeller.data);
  const language = useSelector((state) => state.language.language);
  const { data } = useSelector((state) => state.language);

  return (
    <div className="bestseller-main  main-space mt-5 mb-5">
      <h2>
        <b> {data.cok_satanlar}</b>
      </h2>
      <div className="row">
        {products.slice(0, 4).map((product) => (
          <div
            key={product.product_id}
            className="col-lg-3 col-md-6 col-sm-6 col-6 mb-3"
          >
            <div className="card-type-one p-2 mb-4  d-flex align-items-center justify-content-between flex-column h-100">
              <div className="col-12 d-flex justify-content-end">
                <FavoriteButton product={product} />
              </div>
              <Link
                to={`/${language}/urun-detay/${removeTurkishChars(
                  language == "en"
                    ? product.page_url_en.toLowerCase().replace(/ /g, "-")
                    : product.page_url.toLowerCase().replace(/ /g, "-")
                )}?attribute=${
                  language == "en"
                    ? product.comb_prices[0]?.comb_atts.length > 0 &&
                      product.comb_prices[0]?.comb_atts[0].attribute_value.value_name_en.replace(
                        / /g,
                        "-"
                      )
                    : product.comb_prices[0]?.comb_atts.length > 0 &&
                      product.comb_prices[0]?.comb_atts[0].attribute_value.value_name.replace(
                        / /g,
                        "-"
                      )
                }`}
              >
                <div className="col-12 d-flex justify-content-center products-img">
                  <img
                    crossOrigin="anonymous"
                    src={
                      product.product_images &&
                      product.product_images.length > 0
                        ? REACT_APP_BASE_URL +
                          product.product_images[0].image_url
                        : noImage
                    }
                    alt={
                      product.product_images &&
                      product.product_images.length > 0
                        ? product.product_name
                        : "perfume atelier"
                    }
                    onLoad={(e) => e.target.classList.add("loaded")}
                  />
                </div>
                <h2 className="title-main">
                  <b>
                    {language == "en"
                      ? product.product_name_en
                      : product.product_name}
                  </b>
                </h2>
                <p>
                  <span>
                    {language == "en"
                      ? product.comb_prices[0]?.comb_atts.length > 0 &&
                        product.comb_prices[0]?.comb_atts[0].attribute_value
                          .value_name_en
                      : product.comb_prices[0]?.comb_atts.length > 0 &&
                        product.comb_prices[0]?.comb_atts[0].attribute_value
                          .value_name}
                  </span>
                </p>
                <p>
                  <b>{product.comb_prices[0].price}₺</b>
                </p>
              </Link>
              <AddBasketButton product={product} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Bestseller;
