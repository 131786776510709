import { FETCH_CATEGORY_SUCCESS } from "../actions/categoryActions";

const initState = {
    data: [], 
};

const categoryReducer = (state = initState, action) => {
    switch (action.type) {
        case FETCH_CATEGORY_SUCCESS:
            return {
                ...state,
                data: action.payload, 
            };
        default:
            return state;
    }
};


export default categoryReducer;

