import { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { logoutSuccess, clearUserDetails } from "../redux/actions/authActions"; 

import config from "../config";
const { REACT_APP_BASE_URL } = process.env;

const useAuth = () => {
  const token = useSelector((state) => state.token);
  const [loading, setLoading] = useState(true);
  const [isValidToken, setIsValidToken] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .post(
        REACT_APP_BASE_URL + "users/check-token",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            xsfr: config.secretKey,
            Authorization: `Bearer ${token.tokenAuth}`,
          },
        }
      )
      .then((data) => {
        setIsValidToken(true);
      })
      .catch((error) => {
        setIsValidToken(false);
        dispatch(clearUserDetails()); 
        dispatch(logoutSuccess());
        // dispatch(resetCart());
      })
      .finally(() => {
        setLoading(false);
      });

    
  }, []);

  return { loading, isValidToken };
};

export default useAuth;
