import React, { useState, useEffect } from "react";
import "../assets/css/products.css";
import { IoIosArrowDown } from "react-icons/io";
import noImage from "../assets/img/no_pic.webp";
import { Link } from "react-router-dom";
import { removeTurkishChars } from "../utils/urlUtils";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import Loading from "../wrappers/Loading";
import UtilsHelmet from "../utils/helmet";
import Category from "../utils/Category";
import FavoriteButton from "../utils/FavoriteButton";
import config from "../config";
import AddBasketButton from "../utils/AddBasketButton";
import { useSelector } from "react-redux";

const { REACT_APP_BASE_URL } = process.env;

const ProductsComponent = () => {
  let location = useLocation();
  const language = useSelector((state) => state.language.language);
  const { data } = useSelector((state) => state.language);
  const categories = useSelector((state) => state.category.data);
  const navigate = useNavigate();
  const [canonical, setCanonical] = useState("/urunler");
  const [pageTitle, setPageTitle] = useState(data.urunler);
  const [products, setProducts] = useState([]);
  const [productsView, setProductsView] = useState(null);
  const [categoryView, setCategoryView] = useState([]);
  const [categoryResult, setCategoryResult] = useState({});
  const [sortOption, setSortOption] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [startIndex, setStartIndex] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [openCategories, setOpenCategories] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (location.search) {
      const category = location.search.split("?kategori=")[1];
      var decodedSentence = decodeURIComponent(category.replaceAll("-", " "));

      decodedSentence = decodedSentence.replaceAll("_", "/");
      var arr = [];
      decodedSentence.split("/").map((item) => {
        if (item) {
          arr.push(item);
        }
      });
      setSelectedCategory(decodedSentence);

      setCategoryResult({
        category_name: arr[arr.length - 1],
        category_path: decodedSentence + "/",
        paths: arr,
      });
      const cleanCategory = decodedSentence
        .replaceAll("-", " ")
        .replaceAll("_", " ");
      setCanonical(`/urunler/?kategori=${category}`);

      const metaDescriptionTag = document.querySelector(
        'meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.setAttribute(
          "content",
          cleanCategory +
            (language === "en"
              ? " discover the products in our category. Don't miss online shopping opportunities."
              : " kategorimizdeki ürünleri keşfedin. Online alışveriş fırsatlarını kaçırmayın.")
        );
      } else {
        const newMetaTag = document.createElement("meta");
        newMetaTag.name = "description";
        newMetaTag.content = data.urunler_sayfasi_desc;
        document.head.appendChild(newMetaTag);
      }

      setPageTitle(cleanCategory + data.model_fiyat);
      fetchProductsByCategory(arr[arr.length - 1], decodedSentence + "/");
    } else {
      fetchProducts();

      const metaDescriptionTag = document.querySelector(
        'meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.setAttribute("content", data.urunler_sayfasi_desc);
      } else {
        const newMetaTag = document.createElement("meta");
        newMetaTag.name = "description";
        newMetaTag.content = data.urunler_sayfasi_desc;
        document.head.appendChild(newMetaTag);
      }
    }
  }, [location.search, language]);

  useEffect(() => {
    const initialOpenCategories = categories.reduce((acc, category) => {
      acc.push(category.category_id);
      if (category.children) {
        category.children.forEach((child) => acc.push(child.category_id));
      }
      return acc;
    }, []);
    setOpenCategories(initialOpenCategories);
  }, [categories]);

  const fetchProducts = (resetIndex = false) => {
    const indexToFetch = resetIndex ? 0 : startIndex;
    axios
      .get(`${REACT_APP_BASE_URL}web/read/product?index=${indexToFetch}`, {
        headers: {
          xsfr_token: config.secretKey,
        },
      })
      .then((response) => {
        const newProducts = response.data.response;
        if (newProducts.length === 0) {
          setHasMore(false);
        } else {
          setProducts((prevProducts) => [...prevProducts, ...newProducts]);
          setStartIndex(startIndex + 1);
        }
      })
      .catch((error) => {
        console.error("Hata oluştu:", error);
      });
  };

  const fetchProductsByCategory = (categoryName, path) => {
    axios
      .post(
        REACT_APP_BASE_URL + "web/read/category-find",
        {
          search: path,
        },
        {
          headers: {
            xsfr_token: config.secretKey,
          },
        }
      )
      .then((response) => {
        if (response.data.categories.length > 0) {
          const result = response.data.categories.filter(
            (item) =>
              item.category_path_en === path || item.category_path === path
          );
          var ads = [];
          let languageCatPath =
            language == "en"
              ? result[0].category_path_en
              : result[0].category_path;
          languageCatPath.split("/").map((item) => {
            if (item) {
              ads.push(item);
            }
          });

          const obj = {
            category_name:
              language == "en"
                ? result[0].category_name_en
                : result[0].category_name,
            category_path:
              language == "en"
                ? result[0].category_path_en
                : result[0].category_path,
            paths: ads,
          };
          setCategoryResult(obj);
          var replaceCategory = obj.category_path.replaceAll(" ", "-");
          replaceCategory = replaceCategory.replaceAll("/", "_");
          var encodedSentence = encodeURIComponent(replaceCategory);

          navigate(
            `/${language}/urunler/?kategori=${encodedSentence.slice(
              0,
              encodedSentence.length - 1
            )}`
          );
          // console.log(obj)

          setCategoryView(result[0].products || []);
        } else {
          setCategoryView([]);
        }
      })
      .catch((error) => {
        console.error("Hata oluştu:", error);
      });
  };

  const toggleSortOrder = (e, option) => {
    e.preventDefault();
    setSortOption(option);
    const sortedProducts = sortProducts(categoryView, option);
    setCategoryView(sortedProducts);
  };

  const sortProducts = (products, option) => {
    return [...products].sort((a, b) => {
      switch (option) {
        case "En Düşük Fiyat":
          return (
            parseFloat(a.comb_prices[0]?.price) -
            parseFloat(b.comb_prices[0]?.price)
          );
        case "En Yüksek Fiyat":
          return (
            parseFloat(b.comb_prices[0]?.price) -
            parseFloat(a.comb_prices[0]?.price)
          );
        default:
          return 0;
      }
    });
  };

  useEffect(() => {
    const sortedProducts = sortProducts(products, sortOption);
    const productsView = (
      <InfiniteScroll
        dataLength={sortedProducts.length}
        next={fetchProducts}
        hasMore={hasMore}
        loader={<Loading />}
        endMessage={<p>Ürünler yüklendi...</p>}
      >
        <div className={`col-12 row mt-3`}>
          {sortedProducts.map((product, index) => (
            <div
              className={`col-xl-${
                isMenuOpen ? "4" : "3"
              } col-lg-4 col-md-4 col-sm-6 col-6 mb-3 transitionable-column`}
              key={index}
            >
              <div className="card-type-one p-2 mb-4 d-flex align-items-center justify-content-between h-100 flex-column">
                <div className="col-12 d-flex justify-content-end">
                  <FavoriteButton product={product} />
                </div>
                <Link
                  to={`/${language}/urun-detay/${encodeURIComponent(
                    removeTurkishChars(
                      language == "en"
                        ? product.page_url_en.toLowerCase().replace(/ /g, "-")
                        : product.page_url.toLowerCase().replace(/ /g, "-")
                    )
                  )}?attribute=${
                    language == "en"
                      ? product.comb_prices[0]?.comb_atts.length > 0 &&
                        product.comb_prices[0]?.comb_atts[0].attribute_value.value_name_en.replace(
                          / /g,
                          "-"
                        )
                      : product.comb_prices[0]?.comb_atts.length > 0 &&
                        product.comb_prices[0]?.comb_atts[0].attribute_value.value_name.replace(
                          / /g,
                          "-"
                        )
                  }`}
                >
                  <div className="col-12 d-flex justify-content-center products-img">
                    <img
                      crossOrigin="anonymous"
                      src={
                        product.product_images &&
                        product.product_images.length > 0
                          ? REACT_APP_BASE_URL +
                            product.product_images[0].image_url
                          : noImage
                      }
                      alt={
                        product.product_images &&
                        product.product_images.length > 0
                          ? product.product_name
                          : "perfume atelier"
                      }
                      onLoad={(e) => e.target.classList.add("loaded")}
                    />
                  </div>
                  <h2 className="title-main">
                    <b>
                      {language == "en"
                        ? product.product_name_en
                        : product.product_name}
                    </b>
                  </h2>
                  <p style={{ color: "#bdbdbd" }}>
                    <span>
                      {language == "en"
                        ? product.comb_prices[0]?.comb_atts.length > 0 &&
                          product.comb_prices[0]?.comb_atts[0].attribute_value
                            .value_name_en
                        : product.comb_prices[0]?.comb_atts.length > 0 &&
                          product.comb_prices[0]?.comb_atts[0].attribute_value
                            .value_name}
                    </span>
                  </p>
                  <h6>
                    {product.comb_prices[0]?.price ? (
                      <b>{product.comb_prices[0].price}₺</b>
                    ) : (
                      "bulunamadı"
                    )}
                  </h6>
                </Link>
                <AddBasketButton product={product} />
              </div>
            </div>
          ))}
        </div>
      </InfiniteScroll>
    );
    setProductsView(productsView);
  }, [products, sortOption, isMenuOpen]);

  const calculateCategoryProductCount = (category) => {
    const categoryProducts = products.filter(
      (product) =>
        product.categories &&
        product.categories[0] &&
        product.categories[0].category_name === category.category_name
    );
    return categoryProducts.length;
  };

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleCategory = (category) => {
    var replaceCategory =
      language == "en"
        ? category.category_path_en.replaceAll(" ", "-")
        : category.category_path.replaceAll(" ", "-");
    replaceCategory = replaceCategory.replaceAll("/", "_");
    var encodedSentence = encodeURIComponent(replaceCategory);
    setSelectedCategory(category);
    navigate(
      `/${language}/urunler/?kategori=${encodedSentence.slice(
        0,
        encodedSentence.length - 1
      )}`
    );

    const isOpen = openCategories.includes(category.category_id);
    if (!isOpen) {
      setOpenCategories((prevOpen) => [...prevOpen, category.category_id]);
    }

    const categoryResult = [];
    category.category_path.split("/").map((item) => {
      if (item) {
        categoryResult.push(item);
      }
    });

    const obj = {
      category_name: category.category_name,
      category_path: category.category_path,
      paths: categoryResult,
    };
    setCategoryResult(obj);
    fetchProductsByCategory(category.category_name, category.category_path);
  };

  return (
    <>
      <UtilsHelmet
        title={pageTitle}
        canonical={`https://www.perfumeatelier.com.tr/${language}${canonical}`}
      />
      <h1 className="seo-text"> {pageTitle} Perfume Atelier</h1>
      <div className="products-main container-fluid main-space">
        <div className="row">
          <div className="col-12 d-block">
            <div className="mobile-filter-menu">
              <nav className=" navbar navbar-expand-lg  justify-content-start">
                <button
                  className="button-type-one me-lg-2 me-md-2 me-0"
                  type="button"
                  onClick={toggleMenu}
                >
                  {isMenuOpen ? data.filtreleri_gizle : data.filtreleri_goster}
                </button>
              </nav>
              {isMobile && (
                <Menu
                  isOpen={isMenuOpen}
                  onStateChange={({ isOpen }) => setIsMenuOpen(isOpen)}
                  left
                  className="bg-white"
                >
                  <div className="collapse navbar-collapse">
                    <ul className="navbar-nav d-flex p-3 mt-lg-4 mt-md-4 mt-2 mb-lg-4 mb-md-4 mb-2">
                      {categories.map((category) => (
                        <Category
                          key={category.category_id}
                          category={category}
                          openCategories={openCategories}
                          depth={0}
                          toggleCategory={toggleCategory}
                          productCount={calculateCategoryProductCount(category)}
                          selectedCategory={selectedCategory}
                        />
                      ))}
                    </ul>
                  </div>
                </Menu>
              )}
            </div>
          </div>

          <div className="col-12">
            <div className="col-lg-12 col-md-12 col-12 d-lg-flex d-md-flex justify-content-between align-items-center">
              <div
                className={`col-${
                  isMenuOpen ? "9" : "12"
                } d-lg-flex d-md-flex justify-content-between flex-grow-1`}
              >
                <h2 className="page-title">
                  {!categoryResult ||
                  !categoryResult.paths ||
                  categoryResult.paths.length === 0 ? (
                    <>
                      <b className="m-1"> {data.tum_urunler}</b>
                    </>
                  ) : (
                    <>
                      {categoryResult.paths?.map((item, index) => (
                        <button
                          key={item}
                          style={{
                            width: "max-content",
                          }}
                          className="button-type-one me-lg-2 me-md-2 me-2"
                          onClick={(e) => {
                            e.preventDefault();
                            const updatedCategories =
                              categoryResult.paths.filter(
                                (path) => path !== item
                              );
                            const updatedCategoryPath =
                              updatedCategories.join("/");
                            if (updatedCategoryPath) {
                              fetchProductsByCategory(
                                updatedCategories[updatedCategories.length - 1],
                                updatedCategoryPath + "/"
                              );
                              var replaceCategory =
                                updatedCategoryPath.replaceAll(" ", "-");
                              replaceCategory = replaceCategory.replaceAll(
                                "/",
                                "_"
                              );
                              var encodedSentence =
                                encodeURIComponent(replaceCategory);
                              navigate(
                                `?kategori=${encodedSentence.slice(
                                  0,
                                  encodedSentence.length
                                )}`
                              );
                              setCategoryResult({
                                category_name:
                                  updatedCategories[
                                    updatedCategories.length - 1
                                  ],
                                category_path: updatedCategoryPath,
                                paths: updatedCategories,
                              });
                            } else {
                              navigate();
                              setCategoryView([]);
                              setCategoryResult({});
                            }
                          }}
                        >
                          <div className="d-flex">
                            {item}
                            {index === categoryResult.paths.length - 1 && (
                              <p className="ps-2 m-0 ">
                                <b>X</b>
                              </p>
                            )}
                          </div>
                        </button>
                      ))}
                      <h6 className="mt-3">
                        {categoryResult &&
                        categoryView.length > 0 &&
                        categoryResult.paths.length > 0
                          ? `${data.kategorisinden} ${categoryView.length} ${data.urun_bulundu}`
                          : `${data.urun_bulunamadi}`}
                      </h6>
                    </>
                  )}
                </h2>
                <div className="position-relative mobil-filter-size">
                  <button className="button-type-four " onClick={toggleFilter}>
                    {data.onerilen_siralama}
                    <IoIosArrowDown />
                  </button>
                  {showFilter && (
                    <div
                      className={`card-type-two filter-section  mt-2 w-100 ${
                        showFilter ? "fade-top" : ""
                      }`}
                    >
                      <p
                        onClick={(e) => toggleSortOrder(e, "En Düşük Fiyat")}
                        className="sorted-select-hover"
                      >
                        {data.en_dusuk_fiyat}
                      </p>
                      <p
                        onClick={(e) => toggleSortOrder(e, "En Yüksek Fiyat")}
                        className="sorted-select-hover"
                      >
                        {data.en_yuksek_fiyat}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              {isMenuOpen && !isMobile && (
                <div className="col-3">
                  {categories.map((category) => (
                    <Category
                      key={category.category_id}
                      category={category}
                      openCategories={openCategories}
                      depth={0}
                      toggleCategory={toggleCategory}
                      productCount={calculateCategoryProductCount(category)}
                      selectedCategory={selectedCategory}
                    />
                  ))}
                </div>
              )}
              {categoryView.length === 0 && productsView === null ? (
                <p>Filtrelenen kategori yükleniyor...</p>
              ) : (
                <>
                  {categoryView.length > 0 ? (
                    <div
                      className={`col-xl-${
                        isMenuOpen ? "9" : "12"
                      }  row mt-3 transitionable-column`}
                    >
                      {categoryView.map((product, index) => (
                        <div
                          className={`col-xl-${
                            isMenuOpen ? "4" : "3"
                          } col-lg-4 col-md-4 col-sm-6 col-6 mb-3 transitionable-column`}
                          key={index}
                        >
                          <div className="card-type-one p-2 mb-4  d-flex align-items-center h-100 justify-content-between flex-column">
                            <div className="col-12 d-flex justify-content-end">
                              <FavoriteButton product={product} />
                            </div>
                            <Link
                              to={`/${language}/urun-detay/${encodeURIComponent(
                                removeTurkishChars(
                                  language == "en"
                                    ? product.page_url_en
                                        .toLowerCase()
                                        .replace(/ /g, "-")
                                    : product.page_url
                                        .toLowerCase()
                                        .replace(/ /g, "-")
                                )
                              )}?attribute=${
                                language == "en"
                                  ? product.comb_prices[0]?.comb_atts.length >
                                      0 &&
                                    product.comb_prices[0]?.comb_atts[0].attribute_value.value_name_en.replace(
                                      / /g,
                                      "-"
                                    )
                                  : product.comb_prices[0]?.comb_atts.length >
                                      0 &&
                                    product.comb_prices[0]?.comb_atts[0].attribute_value.value_name.replace(
                                      / /g,
                                      "-"
                                    )
                              }`}
                              className=" justify-content-center"
                            >
                              <div className="col-12 d-flex justify-content-center products-img">
                                <img
                                  crossOrigin="anonymous"
                                  src={
                                    product.product_images &&
                                    product.product_images.length > 0
                                      ? REACT_APP_BASE_URL +
                                        product.product_images[0].image_url
                                      : noImage
                                  }
                                  alt={
                                    product.product_images &&
                                    product.product_images.length > 0
                                      ? product.product_name
                                      : "perfume atelier"
                                  }
                                  onLoad={(e) =>
                                    e.target.classList.add("loaded")
                                  }
                                />
                              </div>
                              <h2 className="title-main">
                                <b>
                                  {language == "en"
                                    ? product.product_name_en
                                    : product.product_name}
                                </b>
                              </h2>
                              <p style={{ color: "#bdbdbd" }}>
                                <span>
                                  {language == "en"
                                    ? product.comb_prices[0]?.comb_atts.length >
                                        0 &&
                                      product.comb_prices[0]?.comb_atts[0]
                                        .attribute_value.value_name_en
                                    : product.comb_prices[0]?.comb_atts.length >
                                        0 &&
                                      product.comb_prices[0]?.comb_atts[0]
                                        .attribute_value.value_name}
                                </span>
                              </p>
                              <h6>
                                <b>
                                  {product.comb_prices[0]?.price ? (
                                    <b>{product.comb_prices[0].price}₺</b>
                                  ) : (
                                    "bulunamadı"
                                  )}
                                </b>
                              </h6>
                            </Link>
                            <AddBasketButton product={product} />
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    productsView
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductsComponent;
