import React, { useState, useEffect } from "react";
import "../assets/css/header.css";
import logo from "../assets/img/logo.svg";
import ShoppingCart from "./ShoppingCart";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import {
  MdOutlineKeyboardArrowRight,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";
import HeaderSectionTwo from "./HeaderSectionTwo";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import userIcon from "../assets/img/icons/hesap.svg";
import userIconHover from "../assets/img/icons/perfume_icon-06.svg";
import sepetIcon from "../assets/img/icons/siparis-sepet.svg";
import sepetIconHover from "../assets/img/icons/perfume_icon-07.svg";
import searchIcon from "../assets/img/icons/perfume_icon-02.svg";
import searchIconHover from "../assets/img/icons/perfume_icon-05.svg";
import SearchBar from "./SearchBar";
import smellData from "../data/smells.json";

const Header = ({ itemCount, isLoggedIn, categoryData }) => {
  const language = useSelector((state) => state.language.language);
  const { data } = useSelector((state) => state.language);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isHovered, setIsHovered] = useState(null);
  const [isSearchOpen, setSearchOpen] = useState(false);

  const handleIconHover = (iconName) => {
    setIsHovered(iconName);
  };

  const handleIconLeave = () => {
    setIsHovered(null);
  };
  const navigate = useNavigate();


  const handleCategoryClick = (
    category,
    isChild = false,
    isSubchild = false
  ) => {
    const paths = language == 'en' ? category.category_path_en : category.category_path
    const replaceCategory = paths
      .replaceAll(" ", "-")
      .replaceAll("/", "_");
    const encodedSentence = encodeURIComponent(replaceCategory);
    setSelectedCategory(category);

    let url = `/${language}/urunler/?kategori=${encodedSentence.slice(
      0,
      encodedSentence.length - 1
    )}`;

    navigate(url);
  };

  return (
    <div className="header-main">
      <HeaderSectionTwo />

      <div className="d-flex justify-content-center header-shadow">
        <div className="col-xl-2 d-flex justify-content-center align-items-center">
          <a aria-label="logo" href={`/${language}`}>
            <img className="header-logo" src={logo} alt="logo"></img>
          </a>
        </div>
        <div className="col-xl-6  p-2">
          <nav>
            <input type="checkbox" id="tm" />
            <ul
              className="nav-dropdown-menu-main cf d-flex justify-content-between"
              style={{ width: "100%" }}
            >
              <li className="nav-dropdown-menu-title-link darkerli">
                <a className="menu-link" href={`/${language}/urunler`}>
                  <span className="nav-dropdown-menu-text-after-icons">
                    {data.urunler}
                  </span>
                </a>
                <input type="checkbox" id="sm04" />
                <li className="nav-dropdown-menu-submenu">
                  {categoryData.map((category) => {
                    const catName = language == 'en' ? category.category_name_en : category.category_name
                    if (
                      catName == "En Yeniler" || catName == "The Newest"||
                      catName == "Çok Satanlar"  || catName == "Best Sellers"
                    ) {
                    } else {
                      return (
                        <li key={category.category_id}>
                          <div
                            className="menu-link"
                            onClick={() => handleCategoryClick(category)}
                          >
                            {catName}
                            {category.children &&
                              category.children.length > 0 && (
                                <span className="nav-dropdown-menu-dropicon-right">
                                  <MdOutlineKeyboardArrowRight />
                                </span>
                              )}
                          </div>
                          <label
                            title="Toggle Drop-down"
                            className="nav-dropdown-menu-dropicon-bottom"
                            htmlFor={`sm${category.category_id}`}
                          >
                            <MdOutlineKeyboardArrowDown />
                          </label>
                          <input
                            type="checkbox"
                            id={`sm${category.category_id}`}
                          />
                          <li className="nav-dropdown-menu-submenu">
                            {category.children &&
                              category.children.map((child) => (
                                <li key={child.category_id}>
                                  <div
                                    className="menu-link"
                                    onClick={() =>
                                      handleCategoryClick(child, true)
                                    }
                                  >
                                    {language == 'en' ? child.category_name_en : child.category_name}
                                    {child.children &&
                                      child.children.length > 0 && (
                                        <span className="nav-dropdown-menu-dropicon-right">
                                          <MdOutlineKeyboardArrowRight />
                                        </span>
                                      )}
                                  </div>
                                  <label
                                    title="Toggle Drop-down"
                                    className="nav-dropdown-menu-dropicon-bottom"
                                    htmlFor={`sm${child.category_id}`}
                                  >
                                    <MdOutlineKeyboardArrowDown />
                                  </label>
                                  <input
                                    type="checkbox"
                                    id={`sm${child.category_id}`}
                                  />
                                  <li className="nav-dropdown-menu-submenu">
                                    {child.children &&
                                      child.children.map((subChild) => (
                                        <li key={subChild.category_id}>
                                          <div
                                            className="menu-link"
                                            onClick={() =>
                                              handleCategoryClick(
                                                subChild,
                                                false,
                                                true
                                              )
                                            }
                                          >
                                            {language == 'en' ? subChild.category_name_en : subChild.category_name}
                                           
                                          </div>
                                        </li>
                                      ))}
                                  </li>
                                </li>
                              ))}
                          </li>
                        </li>
                      );
                    }
                  })}
                </li>
              </li>
              <li className="nav-dropdown-menu-title-link darkerlishadow">
                <a className="menu-link" href={`/${language}/kategoriler`}>
                  <span className="nav-dropdown-menu-text-after-icons">
                  {data.kategoriler}

                  </span>
                </a>
                <input type="checkbox" id="sm00" />
                <li className="nav-dropdown-menu-submenu">
                  {smellData.map((category, index) => (
                    <li key={index}>
                      <Link
                        className="menu-link"
                        to={`/${language}/kategoriler?kategori=${encodeURIComponent(
                          category.title.replace(/ /g, "-")
                        )}`}
                      >
                        {category.title}
                      </Link>
                    </li>
                  ))}
                </li>
              </li>
              <li className="nav-dropdown-menu-title-link darkerli">
                <a
                  href={`/${language}/en-yeniler`}
                  className=" d-flex justify-content-center menu-link"
                >
                  <span className="nav-dropdown-menu-text-after-icons">
                  {data.en_yeniler}
                  </span>
                </a>
              </li>
              <li className="nav-dropdown-menu-title-link darkerli">
                <a
                  href={`/${language}/cok-satanlar`}
                  className=" d-flex justify-content-center menu-link"
                >
                  <span className="nav-dropdown-menu-text-after-icons">
                  {data.cok_satanlar}     
                               </span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="col-xl-3 d-flex justify-content-center align-items-center">
          <ul className="nav-dropdown-menu-body p-0 m-0">
            <li className="nav-dropdown-menu-title-link-2 darkerli header-end-section">
              <span className="nav-dropdown-menu-text-after-icons d-flex gap-4 justify-content-center align-items-center">
                <div className="main-box-shadow d-flex">
                  <SearchBar
                    isSearchOpen={isSearchOpen}
                    onClose={() => setSearchOpen(false)}
                  />
                  <div
                    className="d-flex align-items-center close-button"
                    id="search-main"
                    onMouseEnter={() => handleIconHover("search")}
                    onMouseLeave={handleIconLeave}
                  >
                    <img
                      className="svg-icon"
                      src={
                        isHovered === "search" ? searchIconHover : searchIcon
                      }
                      alt="search-icon"
                    />
                  </div>
                </div>
                {isLoggedIn ? (
                  <Link
                    aria-label="user-account-details"
                    to={`/${language}/user/account-details`}
                    onMouseEnter={() => handleIconHover("user")}
                    onMouseLeave={handleIconLeave}
                  >
                    <img
                      className="svg-icon"
                      src={isHovered === "user" ? userIconHover : userIcon}
                      alt="user-icon"
                    />
                  </Link>
                ) : (
                  <Link
                    aria-label="user-account-details"
                    to={`/${language}/login`}
                    onMouseEnter={() => handleIconHover("user")}
                    onMouseLeave={handleIconLeave}
                  >
                    <img
                      className="svg-icon"
                      src={isHovered === "user" ? userIconHover : userIcon}
                      alt="user-icon"
                    />
                  </Link>
                )}
                <div
                  onClick={() => setIsModalOpen(!isModalOpen)}
                  onMouseEnter={() => handleIconHover("sepet")}
                  onMouseLeave={handleIconLeave}
                  className="d-flex align-items-center"
                >
                  <img
                    className="svg-icon"
                    src={isHovered === "sepet" ? sepetIconHover : sepetIcon}
                    alt="sepet-icon"
                  />
                  <span className="badge bg-black">{itemCount}</span>
                </div>
              </span>
            </li>
          </ul>
        </div>
      </div>

      <ShoppingCart
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

Header.propTypes = {
  itemCount: PropTypes.number,
  isLoggedIn: PropTypes.bool.isRequired,
  userId: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    itemCount: state.cart.cartItems.length,
    isLoggedIn: state.auth.isLoggedIn,
    userId: state.auth.user?.id,
    cartItems: state.cart.cartItems,
    categoryData: state.category.data,
  };
};

export default connect(mapStateToProps)(Header);
