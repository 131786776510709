import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  GET_CART_TOTAL,
  INCREASE_COUNTER,
  DECREASE_COUNTER,
  ADD_TO_FAVORITES,
  REMOVE_FROM_FAVORITES,
  RESET_CART,
} from "../actions/cartActions";

const initialState = {
  cartItems: [],
  favorites: [],
  totalAmount: 0,
  itemCount: 0,
};

const cartReducer = (state = initialState, action) => {

  switch (action.type) {
    case ADD_TO_CART:
      const cartItem = state.cartItems.find(
        (item) => item.comb_att_id === action.payload.comb_att_id
      );
      if (cartItem) {
        return {
          ...state,
          cartItems: state.cartItems.map((item) => {
            if (item.comb_att_id === action.payload.comb_att_id) {
              return {
                ...item,
                count: item.count + action.payload.count,
              };
            }
            return item;
          }),
        };
      }
      return {
        ...state,
        cartItems: [...state.cartItems, action.payload],
      };

    case REMOVE_FROM_CART:
      return {
        ...state,
        cartItems: state.cartItems.filter(
          (item) => item.comb_att_id !== action.payload.comb_att_id
        ),
      };

    case GET_CART_TOTAL:
      const totalAmount = state.cartItems.reduce(
        (total, item) => total + parseFloat(item.product_price) * item.count,
        0
      );
      return {
        ...state,
        totalAmount,
      };

    case INCREASE_COUNTER:
      return {
        ...state,
        cartItems: state.cartItems.map((item, index) => {
          if (index === action.payload) {
            return {
              ...item,
              count: item.count + 1,
            };
          }
          return item;
        }),
      };

    case DECREASE_COUNTER:
      return {
        ...state,
        cartItems: state.cartItems.map((item, index) => {
          if (index === action.payload && item.count > 1) {
            return {
              ...item,
              count: item.count - 1,
            };
          }
          return item;
        }),
      };

      case ADD_TO_FAVORITES:
        const existingIndex = state.favorites.findIndex(
          (item) =>
            item.product_id === action.payload.product_id &&
            (item.value_name === action.payload.value_name ||
             item.value_name_en === action.payload.value_name_en)
        );
  
        if (existingIndex !== -1) {
          return state;
        }
  
        return {
          ...state,
          favorites: [...state.favorites, action.payload],
        };
  
      case REMOVE_FROM_FAVORITES:
        return {
          ...state,
          favorites: state.favorites.filter(
            (item) =>
              item.product_id !== action.payload.product_id ||
              (item.value_name !== action.payload.value_name &&
               item.value_name_en !== action.payload.value_name_en)
          ),
        };
  
    case RESET_CART:
      return {
        ...state,
        cartItems: [],
        itemCount: 0,
        totalAmount: 0, 
      };
    default:
      return state;
  }
};

export default cartReducer;
