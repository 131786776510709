import bcrypt from 'bcryptjs';
import CryptoJS from "crypto-js";

// SHA1 hash oluşturma


const getCurrentDateTime = () => {
  const today = new Date();
  const yyyy = today.getFullYear();
  const mm = String(today.getMonth() + 1);
  const dd = String(today.getDate());

  return `${dd}.${mm}.${yyyy}`;
};
const password = CryptoJS.SHA1(`${process.env.REACT_APP_SECRET_KEY}-${getCurrentDateTime()}`).toString(CryptoJS.enc.Hex);
const salt = bcrypt.genSaltSync(10);

const hash = bcrypt.hashSync(password, salt);

const config = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  tempUrl: process.env.REACT_APP_TEMP_URL,
  username: process.env.REACT_APP_USERNAME,
  password: process.env.REACT_APP_PASSWORD,
  secretKey: hash,
};
// console.log(hash)

export default config;
