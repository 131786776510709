export const FETCH_CATEGORY_SUCCESS = "FETCH_CATEGORY_SUCCESS";

const fetchCategorySuccess = (categoryData) => ({
    type: FETCH_CATEGORY_SUCCESS,
    payload: categoryData,
});


export const fetchCategory= (category) => {
    return (dispatch) => {
        dispatch(fetchCategorySuccess(category));
    };
};
