import { SET_TOKEN} from "../actions/tokenActions";

const initialState = {
    tokenAuth: null,
};

const tokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        tokenAuth: action.payload,
      };
  
    default:
      return state;
  }
};

export default tokenReducer;
