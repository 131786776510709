import React, { useState} from "react";
import "../assets/css/userAccount.css";
import AccountDetails from "../wrappers/AccountDetail";
import Orders from "../wrappers/Orders";
import Addresses from "../wrappers/Adress";
import FavoriteProducts from "../wrappers/Favorites";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import { Navigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { logoutSuccess, clearUserDetails } from "../redux/actions/authActions";
import { resetCart } from "../redux/actions/cartActions";
import { useNavigate } from "react-router-dom";
import Coupon from "../wrappers/Coupon";
import detayImg from "../assets/img/icons/Untitled-1-08.svg";
import detayImgHover from "../assets/img/icons/adres.svg";
import sepetIcon from "../assets/img/icons/siparis-sepet.svg";
import sepetIconHover from "../assets/img/icons/perfume_icon-07.svg";
import userIcon from "../assets/img/icons/hesap.svg";
import userIconHover from "../assets/img/icons/perfume_icon-06.svg";
import kuponIcon from "../assets/img/icons/kupon.svg";
import kuponIconHover from "../assets/img/icons/Untitled-1-11.svg";
import cikisIcon from "../assets/img/icons/cikis.svg";
import cikisIconHover from "../assets/img/icons/Untitled-1-13.svg";
import favIcon from "../assets/img/icons/Untitled-1-14.svg";
import favIconHover from "../assets/img/icons/Untitled-1-15.svg";
import useStorageListener from "../utils/useStorageListener";
import UtilsHelmet from "../utils/helmet";

const UserAccount = () => {
  useStorageListener();
  const userDetails = useSelector(
    (state) => state?.account?.userDetails?.user || null
  );
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const location = useLocation();
  const language = useSelector((state) => state.language.language);
  const { data } = useSelector((state) => state.language);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logoutSuccess());
    dispatch(clearUserDetails());
    dispatch(resetCart());
    navigate("/");
    localStorage.setItem("taskCompleted", Date.now());
  };
  const [isHovered, setIsHovered] = useState(null);

  const handleIconHover = (iconName) => {
    setIsHovered(iconName);
  };

  const handleIconLeave = () => {
    setIsHovered(null);
  };
  if (!isLoggedIn) {
    return <Navigate to="/" replace />;
  }
  return (
    <>
      <UtilsHelmet
        title={data.hesap_detaylari}
        canonical={`https://www.perfumeatelier.com.tr/${language}/user/account-details`}
      />
      <div className="user-account-main container-fluid main-space">
        <div className="col-12 d-flex  gap-lg-5 gap-2">
          <div className="col-lg-3 col-4 account-main top-main d-flex flex-column ">
            {userDetails && (
              <h2>
                <b>
                  {data.merhaba} {userDetails.first_name}
                </b>
              </h2>
            )}
            <div className="account-option mt-4">
              <Link
                className="d-flex align-items-center gap-2"
                to={`/${language}/user/account-details`}
                onMouseEnter={() => handleIconHover("user")}
                onMouseLeave={handleIconLeave}
              >
                <img
                  className="svg-icon"
                  src={isHovered === "user" ? userIconHover : userIcon}
                  alt="sepet-icon"
                />
                <p
                  className="m-0 p-0"
                  style={{
                    color:
                      location.pathname === "/user/account-details"
                        ? "#a13333"
                        : "inherit",
                  }}
                >
                  {data.hesabım}
                </p>
              </Link>
              <Link
                to={`/${language}/user/orders`}
                className="d-flex align-items-center gap-2"
                onMouseEnter={() => handleIconHover("sepet")}
                onMouseLeave={handleIconLeave}
              >
                <img
                  className="svg-icon"
                  src={isHovered === "sepet" ? sepetIconHover : sepetIcon}
                  alt="sepet-icon"
                />
                <p
                  className="m-0 p-0"
                  style={{
                    color:
                      location.pathname === "/user/orders"
                        ? "#a13333"
                        : "inherit",
                  }}
                >
                  {data.siparisler}
                </p>
              </Link>
              <Link
                to={`/${language}/user/addresses`}
                className="d-flex align-items-center gap-2"
                onMouseEnter={() => handleIconHover("adres")}
                onMouseLeave={handleIconLeave}
              >
                <img
                  className="svg-icon"
                  src={isHovered === "adres" ? detayImgHover : detayImg}
                  alt="sepet-icon"
                />
                <p
                  className="m-0 p-0"
                  style={{
                    color:
                      location.pathname === "/user/addresses"
                        ? "#a13333"
                        : "inherit",
                  }}
                >
                  {data.adresler}
                </p>
              </Link>
              <Link
                to={`/${language}/user/favorite-products`}
                className="d-flex align-items-center gap-2"
                onMouseEnter={() => handleIconHover("favorites")}
                onMouseLeave={handleIconLeave}
              >
                <img
                  className="svg-icon"
                  src={isHovered === "favorites" ? favIconHover : favIcon}
                  alt="sepet-icon"
                />
                <p
                  style={{
                    color:
                      location.pathname === "/user/favorite-products"
                        ? "#a13333"
                        : "inherit",
                  }}
                >
                  {data.favorilerim}
                </p>
              </Link>
              <Link
                to={`/${language}/user/kuponlar`}
                className="d-flex align-items-center gap-2"
                onMouseEnter={() => handleIconHover("kupon")}
                onMouseLeave={handleIconLeave}
              >
                <img
                  className="svg-icon"
                  src={isHovered === "kupon" ? kuponIconHover : kuponIcon}
                  alt="sepet-icon"
                />
                <p
                  style={{
                    color:
                      location.pathname === "/user/kuponlar"
                        ? "#a13333"
                        : "inherit",
                  }}
                >
                  {data.kuponlar}
                </p>
              </Link>
            </div>
            <div
              className="d-flex align-items-center gap-2 mt-1"
              onMouseEnter={() => handleIconHover("cikis")}
              onMouseLeave={handleIconLeave}
            >
              <img
                className="svg-icon"
                src={isHovered === "cikis" ? cikisIconHover : cikisIcon}
                alt="sepet-icon"
              />
              <p
                className="p-0 m-0"
                style={{ cursor: "pointer" }}
                onClick={handleLogout}
              >
                {data.cikis_yap}
              </p>
            </div>
          </div>
          <div className="col-lg-8 col-md-8 top-main">
            <Routes>
              <Route path="account-details" element={<AccountDetails />} />
              <Route path="orders" element={<Orders />} />
              <Route path="addresses" element={<Addresses />} />
              <Route path="favorite-products" element={<FavoriteProducts />} />
              <Route path="kuponlar" element={<Coupon />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserAccount;
