import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { setMetaDescription } from "../utils/setMetaDescription";
import UtilsHelmet from "../utils/helmet";

const AboutUsComponent = () => {
  const { data } = useSelector((state) => state.language);
  const language = useSelector((state) => state.language.language);
  useEffect(() => {
    setMetaDescription(
      "Perfume Atelier 2015 yılında, kokunun insan hayatında çok kişisel bir yolculuk olduğuna inanan bir grup genç girişimci tarafından İstanbul’da kuruldu."
    );
  }, []);
  return (
    <>
      <UtilsHelmet
        title={data.hakkimizda}
        canonical={`https://www.perfumeatelier.com.tr/${language}/hakkimizda`}
      />
      <div className="container-fluid main-space">
        <div className="col-12 d-flex align-items-start">
          <div>
            <h1 className="mb-3">
              <b>Perfume Atelier</b>
            </h1>

            <h2> {data.hakkimizda_baslik}</h2>
            <p>{data.hakkimizda_seo}</p>
            <p>{data.hakkimizda_aciklama}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsComponent;
