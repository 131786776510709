import React, { useEffect } from "react";
import blogsData from "../data/blog.json";
import ReactHtmlParser from "react-html-parser";
import { useParams } from "react-router-dom";
import { removeTurkishChars } from "../utils/urlUtils";
import { useSelector } from "react-redux";
import { setMetaDescription } from "../utils/setMetaDescription";
import UtilsHelmet from "../utils/helmet";

const BlogSingleComponent = () => {
  const { title } = useParams();
  const language = useSelector((state) => state.language.language);

  const blogPost = blogsData.find(
    (blog) =>
      removeTurkishChars(blog.title).replace(/\s+/g, "-").toLowerCase() ===
      title
  );
  useEffect(() => {
    setMetaDescription(blogPost.title);
  }, []);
  if (!blogPost) {
    return <p>Blog post not found</p>;
  }

  return (
    <>
      <UtilsHelmet
        title={blogPost.title}
        canonical={`https://www.perfumeatelier.com.tr/${language}/blog/${removeTurkishChars(
          blogPost.title
        )
          .replace(/\s+/g, "-")
          .toLowerCase()}`}
      />
      <div className="container-fluid bg-blog main-space">
        <div className="col-12">
          <h1>{blogPost.title}?</h1>
          <br />
          <img
            className="main-radius"
            src={blogPost.img}
            alt={blogPost.title}
          ></img>
          <p className="mt-4">{ReactHtmlParser(blogPost.info)}</p>
        </div>
      </div>
    </>
  );
};

export default BlogSingleComponent;
