// yayında değil
import React from "react";
import { BsBorderStyle } from "react-icons/bs";
import { IoMdMail } from "react-icons/io";
import { useSelector } from "react-redux";

const OrderTrackingComponent = () => {
  const language = useSelector(state => state.language.language);

  return (
    <>
      <div className="col-lg-10 col-md-12 col-12 main-space container-fluid check-out-main">
        <div className="col-12 d-flex justify-content-center align-items-start">
          <div className="col-lg-9 col-12">
            <h2 className="">Sipariş Takip</h2>
            <p className="mt-3">
              Siparişinizi takip etmek için lütfen aşağıdaki kutuya Sipariş
              Numaranızı girin ve "Siparişi Takip Et" butonuna basın. Bu size
              makbuzunuzda ve almanız gereken onay e-postasında verilmiştir.
            </p>
            <label>Sipariş Numarası</label>
            <div className="input-with-icon">
              <input
                className="w-100 order-details-input"
                type="text"
                placeholder="Sipariş onayı e-postanızda bulabilirsiniz"
              />
              <BsBorderStyle className="icon" />
            </div>
            <label className="mt-3">Fatura E-posta Adresi</label>
            <div className="input-with-icon">
              <input
                className="w-100  order-details-input"
                type="text"
                placeholder="Ödeme sırasında kullandığınız e-posta adresidir"
              />
              <IoMdMail className="icon" />
            </div>
            <div className="col-lg-3 ">
              
              <a href={`/${language}/siparis-takip-detay`}>
              

                <button className="button-type-two mt-3 w-100">
                  Siparişi İzle
                </button>
              </a>
            </div>
            <h5 className="mt-5">
              <b>Ürününüzü Teslim Alırken</b>
            </h5>
            <p>
              Kargo tutanağını imzalamadan önce ürününüzün kutusunda herhangi
              bir hasar yada sorun olup olmadığını kontrol ediniz. Herhangi bir
              nedenle hasar veya eksiklik, kolinin bandında açılma var ise
              teslimatla ilgili hiç bir belgeyi imzalamadan kargo görevlisine
              tutanak tutulması talebiyle birlikte kutunuzu iade ediniz. Bu
              yükümlülüğünüzü yerine getirdiğiniz takdirde, yeni ürünleriniz
              derhal tarafınıza gönderilecektir. Kutusu hasarlı olan, içeriği
              eksik olduğu iddia edilen ürünlerin teslim alınması durumunda
              içindeki ürünlerin hasarından veya eksikliğinden sorumluluğumuz
              bulunmamaktadır. Bu durumu en kısa zamanda Esenkent Mahallesi
              İktibas Sokak No:6 Ümraniye / İstanbul adresimize yazılı olarak ya
              da 0312 543 44 55 numaralı telefonumuza sözlü olarak, sözleşme
              numarası ile birlikte bildiriniz.
            </p>
            <h5>
              <b>Müşteri Hizmetlerimiz</b>
            </h5>
            <p>
              Siparişlerinizle ilgili tüm sorularınız ve ürünlerle ilgili bilgi
              almak için Türkiye'nin her yerinden 0530 012 12 12 numaralı
              telefonumuzdan bize ulaşabilir veya iletişim formumuzu doldurarak
              destek talebinde bulunabilirsiniz.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderTrackingComponent;
