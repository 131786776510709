import React from "react";
import HomeSlider from "../wrappers/HomeSlider";
import Bestseller from "../wrappers/Bestseller";
import TheNewests from "../wrappers/TheNewests";
import HomeSectionOne from "../wrappers/HomeSectionOne";
import HomeSectionTwo from "../wrappers/HomeSectionTwo";
import UtilsHelmet from "../utils/helmet";
import Subscriber from "../wrappers/Subscriber";
import { useSelector } from "react-redux";

const HomeComponent = () => {
  const language = useSelector((state) => state.language.language);

  return (
    <>
      <UtilsHelmet
        title="Perfume Atelier İle Kokuyu Hissedin!"
        canonical={`https://www.perfumeatelier.com.tr/${language}`}
      />
      <h1 className="seo-text">Perfume Atelier</h1>
      <HomeSlider />
      <TheNewests />
      <HomeSectionOne />
      <Bestseller />
      <Subscriber />
      <HomeSectionTwo />
    </>
  );
};

export default HomeComponent;
