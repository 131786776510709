export const  SET_USER_DETAILS  ="SET_USER_DETAILS"

export const setUserDetails = userDetails => ({
  type: SET_USER_DETAILS,
  payload: userDetails,
});

export const  SET_UPDATE_USER_DETAILS  ="SET_UPDATE_USER_DETAILS"

export const setUserUpdateDetails = userDetails => ({
  type: SET_UPDATE_USER_DETAILS,
  payload: userDetails,
});

export const CLEAR_USER_DETAILS = 'CLEAR_USER_DETAILS';

export const clearUserDetails = () => {
  return {
    type: CLEAR_USER_DETAILS
  };
};
export const ADD_ADDRESS = "ADD_ADDRESS";
export const UPDATE_ADDRESS = "UPDATE_ADDRESS";
export const DELETE_ADDRESS = "DELETE_ADDRESS";

export const addAddress = (address) => ({
  type: ADD_ADDRESS,
  payload: address,
});

export const updateAddress = (address) => ({
  type: UPDATE_ADDRESS,
  payload: address,
});

export const deleteAddress = (addressId) => ({
  type: DELETE_ADDRESS,
  payload: addressId,
});