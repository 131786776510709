import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "../assets/css/orders.css";
import axios from "axios";
import { useSelector } from "react-redux";
const { REACT_APP_BASE_URL } = process.env;

const Orders = () => {
  const language = useSelector((state) => state.language.language);
  const { data } = useSelector((state) => state.language);
  const [isImgExpanded, setIsImgExpanded] = useState(false);
  const [isCardRed, setIsCardRed] = useState([]);
  const [isAddressVisible, setIsAddressVisible] = useState([]);
  const [showInvoiceButton, setShowInvoiceButton] = useState([]);
  const userDetails = useSelector((state) => state.account.userDetails);
  const [orders, setOrders] = useState([]);

  const handleCardClick = (index) => {
    const newAddressVisible = isAddressVisible.map((visible, idx) =>
      idx === index ? !visible : false
    );
    const newShowInvoiceButton = showInvoiceButton.map((show, idx) =>
      idx === index ? !show : false
    );
    const newIsCardRed = isCardRed.map((red, idx) =>
      idx === index ? !red : false
    );
    setIsImgExpanded(!isImgExpanded);
    setIsCardRed(newIsCardRed);
    setIsAddressVisible(newAddressVisible);
    setShowInvoiceButton(newShowInvoiceButton);
  };

  useEffect(() => {
    const obj = {
      user_id: userDetails?.user_id || null,
    };
    axios
      .post(REACT_APP_BASE_URL + "perfume-order/find-order", obj)
      .then((response) => {
        setOrders(response.data.response.orders);
        setIsAddressVisible(
          new Array(response.data.response.orders.length).fill(false)
        );
        setShowInvoiceButton(
          new Array(response.data.response.orders.length).fill(false)
        );
        setIsCardRed(
          new Array(response.data.response.orders.length).fill(false)
        );
      });
  }, []);

  const dateFormat = (dates) => {
    const date = new Date(dates);
    const month =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    const hours =
      date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    const minute =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    const mils = date.getMilliseconds();
    const milisecond = mils < 10 ? "0" + mils : mils > 60 ? "12" : mils;
    const dateYear = date.getFullYear() + "-" + month + "-" + day;
    const dateHours =
      hours + ":" + minute + ":" + milisecond.toString().substring(0, 2);
    return dateYear + " " + dateHours;
  };

  const getStatusClass = (currentStatus, targetStatus) => {
    const statusOrder = [
      "Sipariş Beklemede",
      "Sipariş Alındı",
      "Sipariş Hazırlanıyor",
      "Kargoya Hazır",
      "Kargoya Verildi",
      "Ürün Teslim Edildi",
    ];
    return statusOrder.indexOf(currentStatus) >=
      statusOrder.indexOf(targetStatus)
      ? "order-tracking"
      : "order-tracking completed";
  };

  const calculateDiscountedAmount = (totalAmount, couponValue, couponType) => {
    let discountAmount;
    if (couponType === "%") {
      discountAmount = totalAmount - (totalAmount * couponValue) / 100;
    } else if (couponType === "tl") {
      discountAmount = totalAmount + couponValue;
    }
    return discountAmount.toFixed(2);
  };

  return (
    <div className="orders-main">
      <h2>
        <b>{data.siparisler} </b>
      </h2>
      <Tabs>
        <TabList>
          <Tab>{data.devam_eden_siparisler} </Tab>
          <Tab>{data.tamamlanan_siparisler} </Tab>
        </TabList>
        <hr />
        <TabPanel>
          {orders.length > 0 ? (
            orders.map((item, index) => {
              if (item.order_status !== "Ürün Teslim Edildi") {
                let discountAmount = 0;

                return (
                  <div
                    key={index}
                    className={`card-type-two p-2 mb-3 ${
                      isImgExpanded ? "expanded" : ""
                    } ${isCardRed[index] ? "red-background" : ""}`}
                    onClick={() => handleCardClick(index)}
                  >
                    <div className="d-xl-flex d-lg-flex justify-content-between p-2">
                      <div className="d-xl-flex col-xl-9 col-lg-7 col-12">
                        <div className="d-flex justify-content-center flex-column">
                          <h6 className="p-0 m-0 mt-xl-0 mt-3">
                            <b>
                              {data.toplam}: {item.total_amount}₺
                            </b>
                          </h6>
                          <h6 className="p-0 m-0 mt-xl-0 mt-3">
                            <b>
                              {data.kargo}: {item.cargo_price}₺
                            </b>
                          </h6>
                          <p>{item.order_status}</p>
                          {item.cart.comb_atts.map((item2) => {
                            const imgArr =
                              item2.product.product_images.length > 0
                                ? item2.product.product_images.filter(
                                    (item3) =>
                                      item3.image_attribute ==
                                        item2.attribute_value.value_name ||
                                      item3.image_attribute_en ==
                                        item2.attribute_value.value_name_en
                                  )
                                : [{ image_url: "product_images/no_pic.webp" }];
                            return (
                              <>
                                <div
                                  className="d-lg-flex d-md-flex  gap-2 mt-lg-0 mt-2"
                                  key={item2.product.product_id}
                                >
                                  <img
                                    src={
                                      REACT_APP_BASE_URL + imgArr[0].image_url
                                    }
                                    crossOrigin="anonymous"
                                    style={{ width: "50px", height: "50px" }}
                                    className="mb-2"
                                    alt=""
                                  />
                                  <p>
                                    {language == "en"
                                      ? item2.product.product_name_en
                                      : item2.product.product_name}
                                    <span>
                                      x {item2.cart_details.quantity}{" "}
                                      {language == "en" ? "quantity" : "adet"}
                                    </span>
                                    <br />{" "}
                                    {language == "en"
                                      ? item2.attribute_value.value_name_en
                                      : item2.attribute_value.value_name}
                                  </p>

                                  <p>
                                    {(
                                      item2.cart_details.quantity *
                                      parseFloat(item2.cart_details.price)
                                    ).toFixed(2)}
                                    ₺
                                  </p>
                                </div>
                                {isAddressVisible[index] && item.coupon ? (
                                  <div>
                                    <p>
                                      <b>
                                        {data.uygulanan_indirim_kodu}: &nbsp;
                                      </b>
                                      {item.coupon.coupon_code}
                                    </p>
                                    <p>
                                      <b>{data.indirim_oncesi_tutar}: &nbsp;</b>
                                      {(
                                        item2.cart_details.quantity *
                                        parseFloat(item2.cart_details.price)
                                      ).toFixed(2)}
                                      ₺
                                    </p>
                                    <p>
                                      <b>{data.uygulanan_indirim}: &nbsp;</b>-
                                      {item2.cart_details.quantity *
                                        parseFloat(item2.cart_details.price) -
                                        calculateDiscountedAmount(
                                          item2.cart_details.quantity *
                                            parseFloat(
                                              item2.cart_details.price
                                            ),
                                          item.coupon.coupon_value,
                                          item.coupon.coupon_type
                                        )}
                                      ₺
                                    </p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          })}
                          {isAddressVisible[index] && (
                            <div>
                              <div className="d-lg-flex mt-2 gap-5">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                  <h6 className="p-0 m-0">
                                    <b>{data.teslimat_adresi}:</b>
                                  </h6>
                                  <p>{item.shipping_address_area1}</p>
                                  <p>
                                    {item.shipping_address_area2}
                                    {item.shipping_postcode}
                                  </p>
                                  <p>
                                    {item.shipping_district}/
                                    {item.shipping_city}
                                  </p>
                                </div>
                                <div className="mt-lg-0 mt-3 col-xl-6 col-lg-6 col-md-6 col-12">
                                  <h6 className="p-0 m-0">
                                    <b>{data.fatura_adresi} </b>
                                  </h6>
                                  <p>{item.billing_address_area1}</p>
                                  <p>
                                    {item.billing_address_area2}
                                    {item.billing_postcode}
                                  </p>
                                  <p>
                                    {item.billing_district}/{item.billing_city}
                                  </p>
                                </div>
                              </div>
                              <div className="row mt-3 shipping-card">
                                <div className="col-12">
                                  <div className="row justify-content-between">
                                    <div
                                      className={getStatusClass(
                                        item.order_status,
                                        "Sipariş Beklemede"
                                      )}
                                    >
                                      <span className="is-complete" />
                                      <p>{data.sipari_beklemede} </p>
                                    </div>
                                    <div
                                      className={getStatusClass(
                                        item.order_status,
                                        "Sipariş Alındı"
                                      )}
                                    >
                                      <span className="is-complete" />
                                      <p>{data.siparis_alindi} </p>
                                    </div>
                                    <div
                                      className={getStatusClass(
                                        item.order_status,
                                        "Sipariş Hazırlanıyor"
                                      )}
                                    >
                                      <span className="is-complete" />
                                      <p>{data.siparis_hazirlaniyor} </p>
                                    </div>
                                    <div
                                      className={getStatusClass(
                                        item.order_status,
                                        "Kargoya Hazır"
                                      )}
                                    >
                                      <span className="is-complete" />
                                      <p>{data.kargoya_hazir} </p>
                                    </div>
                                    <div
                                      className={getStatusClass(
                                        item.order_status,
                                        "Kargoya Verildi"
                                      )}
                                    >
                                      <span className="is-complete" />
                                      <p>{data.kargoya_verildi} </p>
                                    </div>
                                    <div
                                      className={getStatusClass(
                                        item.order_status,
                                        "Ürün Teslim Edildi"
                                      )}
                                    >
                                      <span className="is-complete" />
                                      <p>{data.urun_teslim_edildi} </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="d-xl-block d-lg-block d-flex justify-content-start align-items-center">
                        <div className="d-flex justify-content-end flex-column p-0 mt-2">
                          <h6>{dateFormat(item.createdAt)}</h6>
                          {!showInvoiceButton[index] && (
                            <button
                              className="button-type-two mt-0"
                              style={{ width: "max-content" }}
                              id="more_btn"
                            >
                              {data.daha_fazla_bilgi}
                            </button>
                          )}
                          {showInvoiceButton[index] && (
                            <div className="d-flex flex-column align-items-lg-end align-items-start gap-2">
                              {item.invoice_link ? (
                                <a
                                  href={REACT_APP_BASE_URL + item.invoice_link}
                                  className="button-type-one"
                                  style={{ width: "max-content" }}
                                  id="fatura_btn"
                                  target="__blank"
                                >
                                  {data.faturayi_goruntule}
                                </a>
                              ) : (
                                ""
                              )}{" "}
                              {item.cargo && item.cargo.cargo_tracking_url ? (
                                <a
                                  href={item.cargo.cargo_tracking_url}
                                  className="button-type-one"
                                  style={{ width: "max-content" }}
                                  id="fatura_btn"
                                  target="__blank"
                                >
                                  {data.kargo_takip}
                                </a>
                              ) : (
                                ""
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })
          ) : (
            <p>{data.devam_eden_siparis_baslik} </p>
          )}
        </TabPanel>
        <TabPanel>
          {orders.length > 0 ? (
            orders.map((item, index) => {
              if (item.order_status === "Ürün Teslim Edildi") {
                if (item.coupon) {
                }

                return (
                  <div
                    key={index}
                    className={`card-type-two p-2 mb-3 ${
                      isImgExpanded ? "expanded" : ""
                    } ${isCardRed[index] ? "red-background" : ""}`}
                    onClick={() => handleCardClick(index)}
                  >
                    <div className="d-xl-flex d-lg-flex justify-content-between p-2">
                      <div className="d-xl-flex col-xl-9 col-lg-9 col-12">
                        <div className="d-flex justify-content-center flex-column">
                          <h6 className="p-0 m-0 mt-xl-0 mt-3">
                            <b>
                              {data.toplam}: {item.total_amount}₺
                            </b>
                          </h6>
                          <h6 className="p-0 m-0 mt-xl-0 mt-3">
                            <b>
                              {data.kargo}: {item.cargo_price}₺
                            </b>
                          </h6>

                          <p>{item.order_status}</p>
                          {item.cart.comb_atts.map((item2) => {
                            return (
                              <>
                                <div
                                  className="d-lg-flex d-md-flex  gap-2 mt-lg-0 mt-2"
                                  key={item2.product.product_id}
                                >
                                  <img
                                    src={
                                      REACT_APP_BASE_URL +
                                      item2.product.product_images[0].image_url
                                    }
                                    crossOrigin="anonymous"
                                    style={{ width: "50px", height: "50px" }}
                                    className="mb-2"
                                    alt=""
                                  />
                                  <p>
                                    {item2.product.product_name}
                                    <span>
                                      x {item2.cart_details.quantity}{" "}
                                      {language == "en" ? "quantity" : "adet"}
                                    </span>
                                    <br /> {item2.attribute_value.value_name}
                                  </p>

                                  <p>
                                    {(
                                      item2.cart_details.quantity *
                                      parseFloat(item2.comb_price.price)
                                    ).toFixed(2)}
                                    ₺
                                  </p>
                                </div>
                                {isAddressVisible[index] && item.coupon ? (
                                  <div>
                                    <p>
                                      <b>
                                        {data.uygulanan_indirim_kodu}: &nbsp;
                                      </b>
                                      {item.coupon.coupon_code}
                                    </p>
                                    <p>
                                      <b>{data.indirim_oncesi_tutar}: &nbsp;</b>
                                      {(
                                        item2.cart_details.quantity *
                                        parseFloat(item2.cart_details.price)
                                      ).toFixed(2)}
                                      ₺
                                    </p>
                                    <p>
                                      <b>{data.uygulanan_indirim}: &nbsp;</b>-
                                      {item2.cart_details.quantity *
                                        parseFloat(item2.cart_details.price) -
                                        calculateDiscountedAmount(
                                          item2.cart_details.quantity *
                                            parseFloat(
                                              item2.cart_details.price
                                            ),
                                          item.coupon.coupon_value,
                                          item.coupon.coupon_type
                                        )}
                                      ₺
                                    </p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          })}
                          {isAddressVisible[index] && (
                            <div>
                              <div className="d-lg-flex mt-2 gap-5">
                                <div className="col-xl-6 col-12">
                                  <h6 className="p-0 m-0">
                                    <b>{data.teslimat_adresi}:</b>
                                  </h6>
                                  <p>{item.shipping_address_area1}</p>
                                  <p>
                                    {item.shipping_address_area2}
                                    {item.shipping_postcode}
                                  </p>
                                  <p>
                                    {item.shipping_district}/
                                    {item.shipping_city}
                                  </p>
                                </div>
                                <div className="mt-lg-0 mt-3 col-xl-6 col-12">
                                  <h6 className="p-0 m-0">
                                    <b>{data.fatura_adresi}:</b>
                                  </h6>
                                  <p>{item.billing_address_area1}</p>
                                  <p>
                                    {item.billing_address_area2}
                                    {item.billing_postcode}
                                  </p>
                                  <p>
                                    {item.billing_district}/{item.billing_city}
                                  </p>
                                </div>
                              </div>
                              <div className="row mt-3 shipping-card">
                                <div className="col-12">
                                  <div className="row justify-content-between">
                                    <div
                                      className={getStatusClass(
                                        item.order_status,
                                        "Sipariş Beklemede"
                                      )}
                                    >
                                      <span className="is-complete" />
                                      <p>{data.sipari_beklemede} </p>
                                    </div>
                                    <div
                                      className={getStatusClass(
                                        item.order_status,
                                        "Sipariş Alındı"
                                      )}
                                    >
                                      <span className="is-complete" />
                                      <p>{data.sipari_beklemede} </p>
                                    </div>
                                    <div
                                      className={getStatusClass(
                                        item.order_status,
                                        "Sipariş Hazırlanıyor"
                                      )}
                                    >
                                      <span className="is-complete" />
                                      <p>{data.siparis_hazirlaniyor} </p>
                                    </div>
                                    <div
                                      className={getStatusClass(
                                        item.order_status,
                                        "Kargoya Hazır"
                                      )}
                                    >
                                      <span className="is-complete" />
                                      <p>{data.kargoya_hazir} </p>
                                    </div>
                                    <div
                                      className={getStatusClass(
                                        item.order_status,
                                        "Kargoya Verildi"
                                      )}
                                    >
                                      <span className="is-complete" />
                                      <p>{data.kargoya_verildi} </p>
                                    </div>
                                    <div
                                      className={getStatusClass(
                                        item.order_status,
                                        "Ürün Teslim Edildi"
                                      )}
                                    >
                                      <span className="is-complete" />
                                      <p>{data.urun_teslim_edildi} </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="d-xl-block d-lg-block d-flex justify-content-start align-items-center">
                        <div className="d-flex justify-content-end flex-column p-0 mt-2">
                          <h6>{dateFormat(item.createdAt)}</h6>
                          {!showInvoiceButton[index] && (
                            <button
                              className="button-type-two mt-0"
                              style={{ width: "max-content" }}
                              id="more_btn"
                            >
                              {data.daha_fazla_bilgi}{" "}
                            </button>
                          )}
                          {showInvoiceButton[index] && (
                            <div className="d-flex flex-column align-items-lg-end align-items-start gap-2">
                              {item.invoice_link ? (
                                <a
                                  href={REACT_APP_BASE_URL + item.invoice_link}
                                  className="button-type-one"
                                  style={{ width: "max-content" }}
                                  id="fatura_btn"
                                  target="__blank"
                                >
                                  {data.faturayi_goruntule}{" "}
                                </a>
                              ) : (
                                ""
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })
          ) : (
            <p>{data.tamamlanan_siparis_baslik} </p>
          )}
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Orders;
