import React from "react";
import "../assets/css/whatsapp.css";
import { IoLogoWhatsapp } from "react-icons/io";

const Whatsapp = () => {
  return (
    <div className="whatsapp-main">
      <a
        target="_blank"
        rel="noreferrer"
        aria-label="home-whatsapp "
        href="https://api.whatsapp.com/send?phone=905300121212"
      >
        <IoLogoWhatsapp />
      </a>
    </div>
  );
};

export default Whatsapp;
