import React from "react";
import logo from "../assets/img/logo.svg";
import "../assets/css/footer.css";
import { FaInstagram } from "react-icons/fa";
import { RiFacebookFill } from "react-icons/ri";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import payment from "../assets/img/payment.webp";
import { removeTurkishChars } from "../utils/urlUtils";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
import app from "../assets/img/app_store.png";
import play from "../assets/img/google_play.png";

const Category = ({ category, selectedCategory }) => {
  const language = useSelector((state) => state.language.language);

  const isSelected =
    selectedCategory && selectedCategory.category_id === category.category_id;

  const navigateButton = (e, categorys) => {
    const replaceCategory = categorys.replaceAll(" ", "-").replaceAll("/", "_");
    const encodedSentence = encodeURIComponent(replaceCategory);

    let url = `/${language}/urunler/?kategori=${encodedSentence.slice(
      0,
      encodedSentence.length - 1
    )}`;
    window.location.replace(url);
  };
  return (
    <ul className="p-0 m-0">
      <li
        className={`d-flex flex-column align-items-lg-start align-items-center`}
        key={category.category_id}
      >
        {category.children && category.children.length > 0 ? (
          <div>
            <span
              style={{ fontWeight: 650 }}
              className={`d-flex justify-content-lg-start justify-content-center mt-2${
                isSelected ? "selected-category" : ""
              }`}
              onClick={(e) =>
                navigateButton(
                  e,
                  language == "en"
                    ? category.category_path_en
                    : category.category_path
                )
              }
            >
              {language == "en"
                ? category.category_name_en
                : category.category_name}
            </span>
            <p className="navbar-nav mb-2 mb-lg-0 d-flex mb-ul align-items-lg-start align-items-center">
              {category.children.map((childCategory) => (
                <Category
                  key={childCategory.category_id}
                  category={childCategory}
                />
              ))}
            </p>
          </div>
        ) : (
          <span
            style={
              category.depth == 1 ? { fontWeight: 650, marginTop: "1vh" } : {}
            }
            onClick={(e) =>
              navigateButton(
                e,
                language == "en"
                  ? category.category_path_en
                  : category.category_path
              )
            }
            className={`${isSelected ? "selected-category" : ""}`}
          >
            {language == "en"
              ? category.category_name_en
              : category.category_name}
          </span>
        )}
      </li>
    </ul>
  );
};
const Footer = ({ categoryData, newProduct, bestSeller }) => {
  const language = useSelector((state) => state.language.language);
  const { data } = useSelector((state) => state.language);

  return (
    <div className="footer-main">
      <div className="footer-inner p-5">
        <div className="col-12  d-lg-flex justify-content-between flex-wrap">
          <div className="col-xl-2 col-lg-3  d-flex flex-column align-items-lg-start align-items-center">
            <div className=" col-12 footer-logo d-flex justify-content-xs-center">
              <a href={`/${language}`}>
                <img className="header-logo" src={logo} alt="logo"></img>
              </a>
            </div>
            <div className="col-12 mt-3 footer-social">
              <div className="d-flex gap-3">
                <a
                  target="_blank"
                  rel="noreferrer"
                  aria-label="instagram"
                  href="https://www.instagram.com/perfumeatelier/"
                >
                  <FaInstagram />
                </a>

                <a
                  target="_blank"
                  rel="noreferrer"
                  aria-label="facebook"
                  href="https://www.facebook.com/perfumeatelier/"
                >
                  <RiFacebookFill />
                </a>

                <a
                  target="_blank"
                  rel="noreferrer"
                  aria-label="twitter"
                  href="https://x.com/i/flow/login?redirect_after_login=%2FPA_turkey"
                >
                  <FaXTwitter />
                </a>

                <a
                  target="_blank"
                  rel="noreferrer"
                  aria-label="linkedin"
                  href="https://tr.linkedin.com/company/perfume-atelier?trk=ppro_cprof"
                >
                  <FaLinkedinIn />
                </a>
              </div>
            </div>
            <br />
            <p>
              <b> {data.iletisime_gecin}</b>
            </p>
            <p>
              <a
                href="mailto:info@perfumeatelier.com.tr"
                aria-label="perfume mail"
              >
                info@perfumeatelier.com.tr
              </a>
            </p>
            <p>
              <a href="tel:+905300121212" aria-label="perfume phone">
                +90 530 012 12 12
              </a>
            </p>
            <br />
            <a href="https://www.google.com/maps/dir//Ba%C4%9Fl%C4%B1ca,+Yeni,+1015.Sokak+No:6%2F1,+06790+Etimesgut%2FAnkara/@39.9101159,32.5583437,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x14d33902f0009903:0x3f1b78ae33bc5458!2m2!1d32.6407446!2d39.9101451?hl=tr&entry=ttu">
              <p className="text-lg-start text-center">
                Yeni Bağlıca Mah. <br />
                1015. Sok. <br />
                No: 6-1 <br /> Etimesgut / Ankara
              </p>
              <br />
            </a>

            <img src={payment} alt="payment"></img>

            <p className="mt-4">
              <b>{data.mobil_uygulama} </b>
            </p>
            <a
              href="https://apps.apple.com/tr/app/perfume-atelier/id6505069260?l=tr"
              target="_blank"
              aria-label="app store perfume atelier"
              rel="noreferrer"
            >
              {" "}
              <img
                className="main-radius mt-3"
                alt="app store perfume atelier"
                src={app}
              ></img>
            </a>

            <a
              href="https://play.google.com/store/apps/details?id=com.osicrew.perfume_atelier&hl=tr"
              target="_blank"
              aria-label="google play perfume atelier"
              rel="noreferrer"
            >
              {" "}
              <img
                className="main-radius mt-3"
                alt="google play perfume atelier"
                src={play}
              ></img>
            </a>
          </div>

          <div className="col-lg-2 col-12  d-flex flex-column align-items-lg-start align-items-center mt-lg-0 mt-3">
            <h3>
              <b className="footer-head"> {data.urunler}</b>
            </h3>
            <div className="navbar-classs d-flex align-items-lg-start align-items-md-center flex-column text-lg-start text-center">
              {categoryData ? (
                <>
                  {categoryData.slice(0, 5).map((category) => {
                    const catName =
                      language == "en"
                        ? category.category_name_en
                        : category.category_name;
                    if (
                      catName == "En Yeniler" ||
                      catName == "The Newest" ||
                      catName == "Çok Satanlar" ||
                      catName == "Best Sellers"
                    ) {
                    } else {
                      return (
                        <Category
                          key={category.category_id}
                          category={category}
                          depth={0}
                        />
                      );
                    }
                  })}
                </>
              ) : (
                "Ürünler bulunamadı."
              )}
            </div>
          </div>
          <div className="col-lg-2  d-flex flex-column align-items-lg-start align-items-center">
            <div className="navbar-classs d-flex align-items-lg-start align-items-md-center flex-column text-lg-start text-center">
              {categoryData ? (
                <>
                  {categoryData.slice(5, 12).map((category) => {
                    const catName =
                      language == "en"
                        ? category.category_name_en
                        : category.category_name;
                    if (
                      catName == "En Yeniler" ||
                      catName == "The Newest" ||
                      catName == "Çok Satanlar" ||
                      catName == "Best Sellers"
                    ) {
                    } else {
                      return (
                        <Category
                          key={category.category_id}
                          category={category}
                          depth={0}
                        />
                      );
                    }
                  })}
                </>
              ) : (
                "Ürünler bulunamadı."
              )}
            </div>
          </div>
          <div className="col-lg-2  d-flex flex-column align-items-lg-start align-items-center ">
            <h3>
              <b className="footer-head"> {data.kategoriler}</b>
            </h3>
            <ul className="p-0 m-0 d-flex flex-column align-items-lg-start align-items-center">
              {[
                "Patchouli",
                "Narco",
                "Bergamot Tea",
                "Rose Violet",
                "Amber",
                "Sandalwood",
                "Jasmine Tea",
              ].map((category, index) => (
                <li
                  style={{ cursor: "pointer" }}
                  key={index}
                  className="header-dropdown-item d-flex flex-column align-items-start "
                >
                  <a
                    href={`/${language}/kategoriler?kategori=${category
                      .toLowerCase()
                      .replace(/ /g, "-")}`}
                  >
                    {category}
                  </a>
                </li>
              ))}
            </ul>
            <h3 className="mt-lg-0 mt-3">
              <a href={`/${language}/cok-satanlar`}>
                <b className="footer-head"> {data.cok_satanlar}</b>
              </a>
            </h3>
            {bestSeller.slice(0, 5).map((product, bestIndex) => (
              <div key={bestIndex}>
                <a
                  href={`/${language}/urun-detay/${removeTurkishChars(
                    language == "en"
                      ? product.page_url_en.toLowerCase().replace(/ /g, "-")
                      : product.page_url.toLowerCase().replace(/ /g, "-")
                  )}?attribute=${
                    language == "en"
                      ? product.comb_prices[0]?.comb_atts.length > 0 &&
                        product.comb_prices[0]?.comb_atts[0].attribute_value.value_name_en.replace(
                          / /g,
                          "-"
                        )
                      : product.comb_prices[0]?.comb_atts.length > 0 &&
                        product.comb_prices[0]?.comb_atts[0].attribute_value.value_name.replace(
                          / /g,
                          "-"
                        )
                  }`}
                >
                  <p className="title-main text-lg-start text-center mt-lg-0 mt-2">
                    {product.product_name}
                  </p>
                </a>
              </div>
            ))}
            <h3 className="mt-lg-0 mt-3">
              <a href={`/${language}/en-yeniler`}>
                <b className="footer-head "> {data.en_yeniler}</b>
              </a>
            </h3>
            {newProduct.slice(0, 5).map((product, indexNew) => (
              <div key={indexNew}>
                <a
                  href={`/${language}/urun-detay/${removeTurkishChars(
                    language == "en"
                      ? product.page_url_en.toLowerCase().replace(/ /g, "-")
                      : product.page_url.toLowerCase().replace(/ /g, "-")
                  )}?attribute=${
                    language == "en"
                      ? product.comb_prices[0]?.comb_atts.length > 0 &&
                        product.comb_prices[0]?.comb_atts[0].attribute_value.value_name_en.replace(
                          / /g,
                          "-"
                        )
                      : product.comb_prices[0]?.comb_atts.length > 0 &&
                        product.comb_prices[0]?.comb_atts[0].attribute_value.value_name.replace(
                          / /g,
                          "-"
                        )
                  }`}
                >
                  <p className="title-main text-lg-start text-center mt-lg-0 mt-2">
                    {product.product_name}
                  </p>
                </a>
              </div>
            ))}
          </div>

          <div className="col-lg-2 d-flex flex-column align-items-lg-start align-items-center mt-lg-0 mt-3">
            <h3>
              <b className="footer-head "> {data.onemli_bilgiler}</b>
            </h3>
            <div className="d-lg-flex text-lg-start text-center">
              <div>
                <a
                  aria-label="mesafeli"
                  href={`/${language}/mesafeli-satis-sozlesmesi`}
                >
                  <p> {data.mesafeli_satis_sozlesmesi}</p>
                </a>

                <a
                  aria-label="kullanim"
                  href={`/${language}/kullanim-sartlari`}
                >
                  <p className="mt-lg-0 mt-2"> {data.kullanim_sartlari}</p>
                </a>
                <a
                  aria-label="gizlilik"
                  href={`/${language}/gizlilik-guvenlik`}
                >
                  <p className="mt-lg-0 mt-2"> {data.gizlilik_guvenlik}</p>
                </a>
                <a aria-label="sss" href={`/${language}/sikca-sorulan-sorular`}>
                  <p className="mt-lg-0 mt-2"> {data.SSS}</p>
                </a>
                <a aria-label="hakkimizda" href={`/${language}/hakkimizda`}>
                  <p className="mt-lg-0 mt-2"> {data.hakkimizda}</p>
                </a>

                <a aria-label="misyonumuz" href={`/${language}/misyonumuz`}>
                  <p className="mt-lg-0 mt-2"> {data.misyonumuz}</p>
                </a>
                <a
                  aria-label="magazalarimiz"
                  href={`/${language}/magazalarimiz`}
                >
                  <p className="mt-lg-0 mt-2"> {data.magazalarimiz}</p>
                </a>
                <a aria-label="urunler" href={`/${language}/urunler`}>
                  <p className="mt-lg-0 mt-2"> {data.tum_urunler}</p>
                </a>
                <a aria-label="blog" href={`/${language}/blog`}>
                  <p className="mt-lg-0 mt-2"> {data.blog}</p>
                </a>
                <a aria-label="iletisim" href={`/${language}/iletisim`}>
                  <p className="mt-lg-0 mt-2"> {data.iletisim}</p>
                </a>
              </div>
            </div>
            <h3 className="mt-2">
              <b className="footer-head"> {data.uyelik}</b>
            </h3>
            <div className="d-lg-flex text-lg-start text-center">
              <div className="">
                <a aria-label="login" href={`/${language}/login`}>
                  <p className="mt-lg-0 mt-2"> {data.giris_yap}</p>
                </a>
                <a aria-label="login-2" href={`/${language}/login`}>
                  <p className="mt-lg-0 mt-2"> {data.kayit_ol}</p>
                </a>

                {/* <a aria-label="siparis" href="/user/orders">
                  <p className="mt-lg-0 mt-2">Sipariş Takip</p>
                </a> */}
                <a aria-label="siparis" href={`/${language}/sifremi-unuttum`}>
                  <p className="mt-lg-0 mt-2"> {data.sifremi_unuttum}</p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 footer-madeby mt-5">
          <p className="madeby ">
            <a href="https://osicrew.com/"> Made by Osicrew</a>
          </p>
          <p>Copyright © 2024 | all rights reserved.</p>
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  itemCount: PropTypes.number,
  isLoggedIn: PropTypes.bool.isRequired,
  userId: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    itemCount: state.cart.cartItems.length,
    isLoggedIn: state.auth.isLoggedIn,
    userId: state.auth.user?.id,
    cartItems: state.cart.cartItems,
    categoryData: state.category.data,
    newProduct: state.newProduct.data,
    bestSeller: state.bestSeller.data,
  };
};

export default connect(mapStateToProps)(Footer);
