import React, { useState, useEffect } from "react";
import "../assets/css/checkout.css";
import { IoMdPerson, IoMdMail } from "react-icons/io";
import { MdLocalPhone, MdApartment } from "react-icons/md";
import { BsMailbox2 } from "react-icons/bs";


const NewCustomer = () => {
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState("");
    const [districts, setDistricts] = useState([]);
    const [selectedDistrict, setSelectedDistrict] = useState("");

  
    useEffect(() => {
      fetch("https://turkiyeapi.dev/api/v1/provinces")
        .then((response) => response.json())
        .then((data) => {
          setCities(data.data);
        })
        .catch((error) => {
          // console.error("Error fetching data:", error);
        });
    }, []);
  
    const handleCityChange = (event) => {
      const selectedCityName = event.target.value;
      setSelectedCity(selectedCityName);
  
      const selectedCityData = cities.find(
        (city) => city.name === selectedCityName
      );
  
      if (selectedCityData) {
        setDistricts(selectedCityData.districts.map((district) => district.name));
      } else {
        setDistricts([]);
      }
      setSelectedDistrict("");
    };
  
    const handleDistrictChange = (event) => {
      setSelectedDistrict(event.target.value);
    };
  return (
    <div>
    <h6>Müşteri Bilgileri</h6>
    <div className="d-lg-flex d-md-flex justify-content-between gap-2">
      <div className="col-lg-6 col-md-6 col-12">
        <div className="input-with-icon">
          <input className="w-100" type="text" placeholder="Ad" />
          <IoMdPerson className="icon" />
        </div>
      </div>
      <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-3">
        <div className="input-with-icon">
          <input
            className="w-100"
            type="text"
            placeholder="Soyad"
          />
          <IoMdPerson className="icon" />
        </div>
      </div>
    </div>
    <div className="d-lg-flex d-md-flex justify-content-between gap-2 mt-3">
      <div className="col-lg-6 col-md-6 col-12">
        <div className="input-with-icon">
          <input
            className="w-100"
            type="text"
            placeholder="E-mail"
          />
          <IoMdMail className="icon" />
        </div>
      </div>
      <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-3">
        <div className="input-with-icon">
          <input
            className="w-100"
            type="text"
            placeholder="Telefon"
          />
          <MdLocalPhone className="icon" />
        </div>
      </div>
    </div>
    <div className="mt-3">
      <textarea
        className="w-100"
        rows={3}
        placeholder="Adres"
      ></textarea>
    </div>
    <div className="d-lg-flex d-md-flex justify-content-between gap-2 mt-3">
      <div className="col-lg-6 col-md-6 col-12">
        <div className="input-with-icon">
          <input
            className="w-100"
            type="text"
            placeholder="Apartman, daire vb."
          />
          <MdApartment className="icon" />
        </div>
      </div>
      <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-3">
        <div className="input-with-icon">
          <input
            className="w-100"
            type="text"
            placeholder="Posta Kodu"
          />
          <BsMailbox2 className="icon" />
        </div>
      </div>
    </div>
    <div className="d-lg-flex d-md-flex justify-content-between gap-2 mt-3">
      <div className="col-lg-6 col-md-6 col-12">
        <div className="input-with-icon">
          <select
            className="w-100"
            value={selectedCity}
            onChange={handleCityChange}
          >
            <option value="">İl Seçin</option>
            {cities.map((city) => (
              <option key={city.id} value={city.name}>
                {city.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-3">
        <div className="input-with-icon">
          <select
            className="w-100"
            value={selectedDistrict}
            onChange={handleDistrictChange}
          >
            <option value="">İlçe Seçin</option>
            {districts.map((district) => (
              <option key={district} value={district}>
                {district}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  </div>
  )
}

export default NewCustomer