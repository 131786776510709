export const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };
  
  export const validatePassword = (password) => {
    const regex = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@#$%^&*(),.?":{}|<>]{6,}$/;
    return regex.test(password);
  };
  
  export const validatePhoneNumber = (phone_number) => {
    const phonePattern = /^\+(?:[0-9] ?){6,14}[0-9]$/;
    return phonePattern.test(phone_number);
  };
  