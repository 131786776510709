import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import config from "../config";
import { removeTurkishChars } from "../utils/urlUtils";
import "../assets/css/header.css";
import "../assets/css/products.css";
import noImage from "../assets/img/no_pic.webp";
import { MdClose } from "react-icons/md";
import { useSelector } from "react-redux";
import { debounce } from "lodash";

const { REACT_APP_BASE_URL } = process.env;

const SearchBar = ({ isSearchOpen, onClose }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const language = useSelector((state) => state.language.language);
  const { data } = useSelector((state) => state.language);

  const searchBarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target)
      ) {
        setSearchTerm("");
        setFilteredProducts([]);
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const fetchProducts = async (productName) => {
    if (!productName || productName.length < 2) {
      setFilteredProducts([]);
      return;
    }
    try {
      const response = await axios.post(
        REACT_APP_BASE_URL + "web/search/product",
        {
          search: productName,
        },
        {
          headers: {
            xsfr: config.secretKey,
          },
        }
      );
      setFilteredProducts(response.data.response);
    } catch (error) {
      console.error("Ürünleri alma başarısız oldu:", error);
    }
  };

  const debouncedFetchProducts = useCallback(debounce(fetchProducts, 300), []);

  const handleSearchChange = (event) => {
    const productName = event.target.value;
    setSearchTerm(productName);
    debouncedFetchProducts(productName);
  };

  return (
    <div ref={searchBarRef}>
      <div className="d-flex justify-content-between align-items-center ">
        <div className="col-12 d-flex justify-content-xl-center justify-content-start">
          <div>
            <div className="d-flex align-items-center flex-row-reverse col-12 position-relative">
              <input
                type="text"
                id="search-bar"
                className="active p-md-3 p-lg-1 p-2"
                placeholder={data.ara}
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <MdClose
                className="close-button-mobile"
                onClick={onClose}
                size={20}
              />
            </div>
            {searchTerm && filteredProducts.length > 0 ? (
              <div className="position-absolute search-lg-main bg-white">
                {filteredProducts.map((product, index) => (
                  <div className="m-2" key={index}>
                    <div className="col-12 card-type-search" key={index}>
                      <a
                        href={`/${language}/urun-detay/${removeTurkishChars(
                          language === "en"
                            ? product.page_url_en
                                .toLowerCase()
                                .replace(/ /g, "-")
                            : product.page_url.toLowerCase().replace(/ /g, "-")
                        )}?attribute=${
                          language === "en"
                            ? product.comb_prices[0]?.comb_atts.length > 0 &&
                              product.comb_prices[0]?.comb_atts[0].attribute_value.value_name_en.replace(
                                / /g,
                                "-"
                              )
                            : product.comb_prices[0]?.comb_atts.length > 0 &&
                              product.comb_prices[0]?.comb_atts[0].attribute_value.value_name.replace(
                                / /g,
                                "-"
                              )
                        }`}
                      >
                        <div className="col-12 d-flex justify-content-center align-items-center p-1">
                          <div className="col-4 d-flex justify-content-center">
                            <img
                              className="p-3"
                              crossOrigin="anonymous"
                              src={
                                product.product_images &&
                                product.product_images.length > 0
                                  ? REACT_APP_BASE_URL +
                                    product.product_images[0].image_url
                                  : noImage
                              }
                              alt={"Perfume Atelier"}
                              onLoad={(e) => e.target.classList.add("loaded")}
                            />
                          </div>
                          <div className="col-8">
                            <h6>
                              {language === "en"
                                ? product.product_name_en
                                : product.product_name}
                            </h6>
                            <span>
                              {language === "en"
                                ? product.comb_prices[0]?.comb_atts.length >
                                    0 &&
                                  product.comb_prices[0]?.comb_atts[0]
                                    .attribute_value.value_name_en
                                : product.comb_prices[0]?.comb_atts.length >
                                    0 &&
                                  product.comb_prices[0]?.comb_atts[0]
                                    .attribute_value.value_name}
                            </span>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              searchTerm && (
                <div className="position-absolute search-lg-main no-results-message">
                  <h6 className="p-1">{data.not_found_product}</h6>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
