import React, { useState, useEffect } from 'react';
import spinner from "../assets/img/load.gif";

const Loading = () => {
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {showLoading && (
        <div className=' d-flex justify-content-center align-items-center' style={{height:"100vh"}}>
          <img  style={{height:"10vh"}} src={spinner} alt="Loading Spinner" />
        </div>
      )}
    </>
  );
}

export default Loading;
