import React from "react";
import { Helmet } from "react-helmet";

const UtilsHelmet = ({ title, description, canonical }) => {
  return (
    <Helmet>
      <title>{title} | Perfume Atelier</title>
      {description && <meta name="description" content={description} />}
      {canonical && <link rel="canonical" href={canonical} />}
    </Helmet>
  );
};

export default UtilsHelmet;
