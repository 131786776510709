import React, { useState, useEffect } from "react";
import "../assets/css/shoppingCart.css";
import { IoIosArrowBack } from "react-icons/io";
import { TiShoppingCart } from "react-icons/ti";
import { connect, useDispatch } from "react-redux";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { IoClose } from "react-icons/io5";
import sepetIcon from "../assets/img/icons/siparis-sepet.svg";
import sepetIconHover from "../assets/img/icons/Untitled-1-17.svg";
import {
  getCartTotal,
  increaseCounter,
  decreaseCounter,
  removeFromCart,
} from "../redux/actions/cartActions";
import config from "../config";
import useStorageListener from "../utils/useStorageListener";

const { REACT_APP_BASE_URL } = process.env;

const ShoppingCart = ({ isOpen, onClose, cartItems, totalAmount, counter }) => {
  useStorageListener();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const token = useSelector((state) => state.token);
  const language = useSelector((state) => state.language.language);
  const { data } = useSelector((state) => state.language);
  const [cargoPrice, setCargoPrice] = useState(0);
  const [freeCargo, setFreeCargo] = useState(0);

  useEffect(() => {
    const fetchCargoPrice = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}cargo-price/read`,
          {
            headers: {
              xsfr: config.secretKey,
              Authorization: `Bearer ${token.tokenAuth}`,
            },
          }
        );

        if (response.data.response.length > 0) {
          const result = response.data.response.filter(
            (item) => item.cargo_price == 0
          );
          if (result.length > 0) {
            setCargoPrice(result[0].min_price);
          }
        }
      } catch (error) {
        console.error("Error fetching cargo price:", error);
      }
    };

    fetchCargoPrice();
  }, []);

  useEffect(() => {
    let freeCargo = parseFloat(cargoPrice) - parseFloat(totalAmount);
    setFreeCargo(freeCargo);
    if (freeCargo > 0) {
    }
  }, [totalAmount]);

  useEffect(() => {
    dispatch(getCartTotal());
  }, [cartItems, dispatch]);

  const [isClosing, setIsClosing] = useState(false);

  const decreaseCounterHandler = async (index) => {
    const updatedCart = [...cartItems];
    if (updatedCart[index].count > 1) {
      updatedCart[index] = {
        ...updatedCart[index],
        count: updatedCart[index].count - 1,
        quantity: parseFloat(updatedCart[index].count) - 1,
      };
      localStorage.setItem("taskCompleted", Date.now());

      dispatch(decreaseCounter(index));

      try {
        if (cartItems.length > 0) {
          if (cartItems[0].cart_id) {
            await updateCart(updatedCart);
          }
        }
      } catch (error) {
        console.error(error.message);
      }
    }
  };
  const [isHovered, setIsHovered] = useState(null);

  const handleIconHover = (iconName) => {
    setIsHovered(iconName);
  };

  const handleIconLeave = () => {
    setIsHovered(null);
  };
  const increaseCounterHandler = async (index) => {
    const updatedCart = [...cartItems];
    updatedCart[index] = {
      ...updatedCart[index],
      count: updatedCart[index].count + 1,
      quantity: parseFloat(updatedCart[index].count) + 1,
    };
    localStorage.setItem("taskCompleted", Date.now());

    dispatch(increaseCounter(index));

    try {
      if (cartItems.length > 0) {
        if (cartItems[0].cart_id) {
          await updateCart(updatedCart);
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const updateCart = async (updatedCart) => {
    try {
      const response = await axios.post(
        REACT_APP_BASE_URL + "cart/update",
        {
          cart_id: cartItems.length > 0 ? cartItems[0].cart_id : null,
          comb_atts: updatedCart.map((item) => ({
            comb_att_id: item.comb_att_id,
            quantity: item.count.toString(),
          })),
        },
        {
          headers: {
            xsfr: config.secretKey,
            Authorization: `Bearer ${token.tokenAuth}`,
          },
        }
      );
    } catch (error) {
      throw new Error("Error updating cart:", error);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      const timeout = setTimeout(() => {
        setIsClosing(true);
      }, 50);
      return () => clearTimeout(timeout);
    } else {
      setIsClosing(false);
    }
  }, [isOpen]);

  const handleClose = () => {
    onClose();
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("modal-overlay")) {
      handleClose();
    }
  };

  const deleteCartProductItem = async (e, item) => {
    e.preventDefault();
    if (item.cart_id) {
      await axios.post(
        REACT_APP_BASE_URL + "cart/deleteRedux",
        {
          cart_id: item.cart_id,
          comb_att_id: item.comb_att_id,
        },
        {
          headers: {
            xsfr: config.secretKey,
            Authorization: `Bearer ${token.tokenAuth}`,
          },
        }
      );
    }
    localStorage.setItem("taskCompleted", Date.now());

    dispatch(removeFromCart(item));
    toast.success(data.sepetten_kaldirildi);
  };
  const handleCheckoutClick = (e) => {
    if (cartItems.length === 0) {
      e.preventDefault();
      toast.warn(data.sepete_urun_ekle);
    } else if (!isLoggedIn) {
      toast.warn(data.kullanici_giris_sayfasi_yonlendirme);
      setTimeout(() => {
        window.location.replace(`/login`);
      }, 3000);
    }
  };
  return (
    <div className="cart-main">
      <div
        className={`modal-overlay ${isOpen ? "open" : ""}`}
        onClick={handleOverlayClick}
      >
        <div
          className={`modal-content d-flex justify-space-between${
            isClosing ? "closing" : ""
          }`}
        >
          <div className="d-flex justify-content-between align-items-center shopping-border p-2">
            <IoIosArrowBack onClick={handleClose} />
            <div className="d-flex align-items-center justify-content-center">
              <h4 className="p-0 mx-3 mb-0">{data.sepetim} </h4>
              <TiShoppingCart />
            </div>
          </div>
          <div className="col-12 cart-inner">
            {totalAmount == 0 ? (
              ""
            ) : parseFloat(cargoPrice) - parseFloat(totalAmount) > 0 ? (
              <button className="btn btn-outline-warning" disabled>
                {data.sepet_cargo_msg1}
                {parseFloat(cargoPrice) - parseFloat(totalAmount)}₺
                {data.sepet_cargo_msg2}
              </button>
            ) : (
              <button className="btn btn-outline-success" disabled>
                {data.sepet_cargo_msg3}
              </button>
            )}

            {cartItems.length === 0 && (
              <div className="empty-cart-message">
                <h6 className="m-2">{data.sepetinizde_urun_bulunmamakta} </h6>
              </div>
            )}
            {cartItems.map((item, index) => (
              <div
                key={index}
                className="mt-3 card-type-two d-flex justify-content-center align-items-center position-relative p-2"
              >
                <div className="col-4">
                  <img
                    crossOrigin="anonymous"
                    className="w-100 p-2"
                    src={REACT_APP_BASE_URL + item.product_image_url}
                    alt={item.product_name}
                  />
                </div>
                <div className="col-8">
                  <div className="d-flex justify-content-between">
                    <div>
                      <p className="title-main">
                        <b>{item.product_name}</b>
                      </p>
                      <span>
                        {/* {item.product_category_name} */}
                        {item.product_image_attributes}
                      </span>
                    </div>
                    <div>
                      <button
                        className="bg-danger btn text-white delete-button d-flex w-100 align-items-center "
                        onClick={(e) => deleteCartProductItem(e, item)}
                      >
                        {data.delete} <IoClose />
                      </button>
                    </div>
                  </div>

                  <div className="d-flex align-items-center justify-content-between mt-3">
                    <div className="counter col-lg-4 col-md-4 col-7 d-flex p-1 ">
                      <div
                        className="col-4 d-flex justify-content-center counter-inner"
                        onClick={() => decreaseCounterHandler(index)}
                      >
                        -
                      </div>
                      <div className="col-4 d-flex justify-content-center ">
                        {item.count && item.count.toString().padStart(2, "0")}
                      </div>
                      <div
                        className="col-4 d-flex  justify-content-center counter-inner"
                        onClick={() => increaseCounterHandler(index)}
                      >
                        +
                      </div>
                    </div>
                    <div className="col-4 d-flex flex-column align-items-end">
                      <h6 className="m-0 p-0">
                        <b>{item.count * Number(item.product_price)}₺</b>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div>
            <div className="d-flex align-items-center justify-content-between  mt-2">
              <h4 className="p-0 mb-0 ">{data.toplam}: </h4>
              <h4 className=""> {totalAmount}₺</h4>
            </div>
            <div>
              {isLoggedIn ? (
                <a
                  href={`/${language}/check-out`}
                  onClick={handleCheckoutClick}
                >
                  <button
                    className="button-type-one w-100 mt-2 p-1 d-flex align-items-center justify-content-center gap-2"
                    onMouseEnter={() => handleIconHover("sepet")}
                    onMouseLeave={handleIconLeave}
                  >
                    <img
                      className="svg-icon"
                      src={isHovered === "sepet" ? sepetIconHover : sepetIcon}
                      alt="sepet-icon"
                    />
                    {data.siparisi_tamamla}
                  </button>
                </a>
              ) : (
                <div onClick={handleCheckoutClick}>
                  <button
                    className="button-type-one w-100 mt-2 p-1 d-flex align-items-center justify-content-center gap-2"
                    onMouseEnter={() => handleIconHover("user")}
                    onMouseLeave={handleIconLeave}
                  >
                    <img
                      className="svg-icon"
                      src={isHovered === "sepet" ? sepetIconHover : sepetIcon}
                      alt="sepet-icon"
                    />
                    {data.siparisi_tamamla}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cart.cartItems,
    totalAmount: state.cart.totalAmount,
    counter: state.cart.counter,
  };
};

export default connect(mapStateToProps)(ShoppingCart);
