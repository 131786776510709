import React from "react";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  addToFavorites,
  removeFromFavorites,
} from "../redux/actions/cartActions";

const FavoriteButton = ({ product }) => {
  const dispatch = useDispatch();
  const favorites = useSelector((state) => state.cart.favorites);
  const language = useSelector(state => state.language.language);
  const { data } = useSelector((state) => state.language);

  const addFavorites = () => {
    const favExists = favorites.some(
      (fav) =>
        fav.product_id === product.product_id &&
        (fav.value_name_en === product.comb_prices[0]?.comb_atts[0].attribute_value.value_name_en ||
         fav.value_name === product.comb_prices[0]?.comb_atts[0].attribute_value.value_name)
    );

    if (favExists) {
      const favIndex = favorites.findIndex(
        (fav) =>
          fav.product_id === product.product_id &&
          (fav.value_name_en === product.comb_prices[0]?.comb_atts[0].attribute_value.value_name_en ||
           fav.value_name === product.comb_prices[0]?.comb_atts[0].attribute_value.value_name)
      );

      if (favIndex !== -1) {
        dispatch(removeFromFavorites(favorites[favIndex]));
        toast.error(data.urun_favorilerden_kaldirildi);
      }
    } else {
      const favs = {
        product_name: language === 'en' ? product.product_name_en : product.product_name,
        product_id: product.product_id,
        product_price: product.comb_prices[0].price,
        value_name: product.comb_prices[0]?.comb_atts[0].attribute_value.value_name,
        value_name_en: product.comb_prices[0]?.comb_atts[0].attribute_value.value_name_en,
        product_image_url: product.product_images.length > 0 && product.product_images[0].image_url,
        product_image_attributes: language === 'en'
          ? product.product_images.length > 0 && product.product_images[0].image_attribute_en
          : product.product_images.length > 0 && product.product_images[0].image_attribute,
        page_url: language === 'en' ? product.page_url_en : product.page_url,
      };

      dispatch(addToFavorites(favs));
      toast.success(data.favorilere_eklendi);
    }
  };

  return (
    <div onClick={addFavorites}>
      {favorites.find(
        (fav) =>
          fav.product_id === product.product_id &&
          (fav.value_name_en === product.comb_prices[0]?.comb_atts[0].attribute_value.value_name_en ||
           fav.value_name === product.comb_prices[0]?.comb_atts[0].attribute_value.value_name)
      ) ? (
        <FaHeart className="heart-icon" />
      ) : (
        <FaRegHeart className="heart-icon " />
      )}
    </div>
  );
};

export default FavoriteButton;
