import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { resetCart } from "./redux/actions/cartActions";
import { logoutSuccess, clearUserDetails } from "./redux/actions/authActions";
import { createStore, applyMiddleware } from "redux";
import {thunk} from 'redux-thunk';
import './index.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import rootReducer from "./redux/reducers/rootReducer";
import config from "./config";
import { save, load } from "redux-localstorage-simple";
import { fetchCategory } from "./redux/actions/categoryActions";
import { fetchNewProduct } from "./redux/actions/newProductActions";
import { fetchBestSeller } from "./redux/actions/bestSellerActions";
import { composeWithDevTools } from '@redux-devtools/extension';
import axios from "axios";
import englishData from './data/English.json';
import turkishData from './data/Turkish.json';
import { SET_LANGUAGE_DATA,SET_CURRENT_LANGUAGE } from "./redux/actions/languageActions";

const { REACT_APP_BASE_URL } = process.env;
const defaultLanguage = 'tr';

const getLanguageData = (language) => {
  switch (language) {
    case 'tr':
      return turkishData;
    default:
      return englishData;
  }
};

const urlLanguage = window.location.pathname.split('/').find(part => ['en', 'tr'].includes(part)) || defaultLanguage;

const initialState = {
  language: urlLanguage,
  data: getLanguageData(urlLanguage)
};

const store = createStore(
  rootReducer,
  { ...initialState, ...load() },
  composeWithDevTools(applyMiddleware(thunk, save()))
);

store.subscribe(() => {
  const state = store.getState();
  localStorage.setItem('language', state.language.language);
  updateLanguageInURL(state.language.language);
});




export function updateLanguageInURL(language) {
  const currentPath = window.location.pathname;
  const parts = currentPath.split('/');
  const languageIndex = parts.findIndex(part => ['en', 'tr'].includes(part));

  if (languageIndex !== -1) {
    parts[languageIndex] = language;
  } else {
    parts.splice(1, 0, language); 
  }

  const newPath = parts.join('/');
  const currentSearch = window.location.search; 
  window.history.replaceState(null, null, `${newPath}${currentSearch}`); 
}


export const handleLanguageChange = (language) => {
  store.dispatch({ type: SET_CURRENT_LANGUAGE, payload: language });
  store.dispatch({ type: SET_LANGUAGE_DATA, payload: getLanguageData(language) });
  updateLanguageInURL(language);
};

export const handleInitialLanguage = () => {
  const urlLanguage = window.location.pathname.split('/').find(part => ['en', 'tr'].includes(part)) || defaultLanguage;
  store.dispatch({ type: SET_CURRENT_LANGUAGE, payload: urlLanguage });
  store.dispatch({ type: SET_LANGUAGE_DATA, payload: getLanguageData(urlLanguage) });
};
handleInitialLanguage();

const customOrder = [
  "Parfüm",
  "Kolonya",
  "Kolonyalı Mendil",
  "Ortam Kokuları",
  "Mum",
  "Kişisel Bakım",
  "Hediye Seti",
  "Aksesuar"
];

const sortCategories = (categories) => {
  const lowerCaseOrder = customOrder.map(category => category.toLowerCase());

  return categories.slice().sort((a, b) => {
    const nameA = a.category_name.toLowerCase();
    const nameB = b.category_name.toLowerCase();
    
    const indexA = lowerCaseOrder.indexOf(nameA);
    const indexB = lowerCaseOrder.indexOf(nameB);

    return indexA - indexB;
  });
};
const fetchData = async () => {
  try {
    const categoryResponse = await axios.get(
      `${REACT_APP_BASE_URL}web/tree/category`,
      {
        headers: {
          xsfr: config.secretKey,
        },
      }
    );
    
    // Kategorileri sıralayın
    const sortedCategories = sortCategories(categoryResponse.data.categories);
    store.dispatch(fetchCategory(sortedCategories));
    console.log('Category Response:', categoryResponse.data.categories);
    console.log('Sorted Categories:', sortedCategories);
    

    const newProductResponse = await axios.post(
      `${REACT_APP_BASE_URL}web/read/category-find`,
      { search: 'En Yeniler' },
      { headers: { xsfr: config.secretKey } }
    );
    store.dispatch(fetchNewProduct(newProductResponse.data.categories[0].products));

    const bestSellerResponse = await axios.post(
      `${REACT_APP_BASE_URL}web/read/category-find`,
      { search: 'Çok Satanlar' },
      { headers: { xsfr: config.secretKey } }
    );
    store.dispatch(fetchBestSeller(bestSellerResponse.data.categories[0].products));
    
    const state = store.getState();

    if (state.token.tokenAuth) {
      await axios
        .post(
          `${REACT_APP_BASE_URL}users/check-token`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              xsfr: config.secretKey,
              Authorization: `Bearer ${state.token.tokenAuth}`,
            },
          }
        )
        .then((res) => {})
        .catch((err) => {
          store.dispatch(logoutSuccess());
          store.dispatch(clearUserDetails());
          store.dispatch(resetCart());
        });
    }

  } catch (error) {
    console.error("Hata:", error);
  }
};

const Root = () => {
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};

ReactDOM.createRoot(document.getElementById("root")).render(<Root />);
