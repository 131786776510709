import { combineReducers } from "redux";
import categoryReducer from "./categoryReducer";
import cartReducer from "./cartReducer";
import accountReducer from './accountReducer';
import authReducer from "./authReducer";
import tokenReducer from "./tokenReducer";
import newProductReducer from "./newProductReducer";
import bestSellerReducer from "./bestSellerReducer";
import languageReducer from "./languageReducer"; 

const rootReducer = combineReducers({
  category: categoryReducer,
  cart: cartReducer,
  account: accountReducer,
  auth: authReducer,
  token: tokenReducer,
  newProduct: newProductReducer,
  bestSeller: bestSellerReducer,
  language: languageReducer
});

export default rootReducer;
