export const FETCH_NEW_SUCCESS = "FETCH_NEW_SUCCESS";

const fetchNewProductSuccess = (newProduct) => ({
    type: FETCH_NEW_SUCCESS,
    payload: newProduct,
});


export const fetchNewProduct= (newProduct) => {
    return (dispatch) => {
        dispatch(fetchNewProductSuccess(newProduct));
    };
};
