import React, { useState, useEffect } from "react";
import faqData from "../data/faq.json";
import { FaMinus, FaPlus } from "react-icons/fa";
import UtilsHelmet from "../utils/helmet";
import { useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { setMetaDescription } from "../utils/setMetaDescription";

const FAQComponent = () => {
  const [activeId, setActiveId] = useState(null);
  const language = useSelector((state) => state.language.language);
  const { data } = useSelector((state) => state.language);

  const toggleAccordion = (id) => {
    setActiveId(activeId === id ? null : id);
  };

  useEffect(() => {
    setMetaDescription(
      "Müşterilerimiz tarafından en sık sorulan sorulara yanıtlar. Ürünlerimiz, hizmetlerimiz, sipariş ve teslimat süreçleri hakkında merak ettiğiniz tüm bilgileri burada bulabilirsiniz."
    );
  }, []);
  return (
    <>
      <UtilsHelmet
        title={data.sikca_sorulan_sorular}
        canonical={`https://www.perfumeatelier.com.tr/${language}/sikca-sorulan-sorular`}
      />
      <div className="container-fluid main-space">
        <div className="">
          <div className="">
            <h1 className="mb-3">
              <b>{data.sorulariniz_mi_var} </b>
            </h1>

            {faqData.map((faq) => (
              <div
                key={faq.id}
                className="d-flex accordion-item m-lg-4 m-md-4 m-3 col-12"
              >
                <button
                  id="acc_btn"
                  style={{ borderBottom: "2px solid #000", width: "60vw" }}
                  className="ps-2 pb-1 accordion-button d-flex flex-column align-items-start"
                  onClick={() => toggleAccordion(faq.id)}
                  aria-expanded={activeId === faq.id ? "true" : "false"}
                >
                  <div className="d-flex">
                    <span className="me-2">
                      {activeId === faq.id ? <FaMinus /> : <FaPlus />}
                    </span>
                    <b>
                      {ReactHtmlParser(
                        language == "en" ? faq.question_en : faq.question
                      )}
                    </b>
                  </div>

                  <div
                    className={`accordion-collapse collapse ${
                      activeId === faq.id ? "pt-3 d-flex flex-column" : "d-none"
                    }`}
                  >
                    <div className="accordion-body">
                      <b>
                        {ReactHtmlParser(
                          language == "en" ? faq.answer_en : faq.answer
                        )}
                      </b>
                    </div>
                  </div>
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQComponent;
