import { SET_USER_DETAILS,SET_UPDATE_USER_DETAILS} from "../actions/accountActions";
import { CLEAR_USER_DETAILS } from "../actions/accountActions"; 
import { ADD_ADDRESS, UPDATE_ADDRESS, DELETE_ADDRESS } from '../actions/accountActions';

const initialState = {
  userDetails: null,
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
      };
    case CLEAR_USER_DETAILS: 
      return {
        ...state,
        userDetails: null,
      };
      case ADD_ADDRESS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          user_addresses: [...state.userDetails.user_addresses, action.payload],
        },
      };
      case SET_UPDATE_USER_DETAILS:
        return {
          ...state,
          userDetails: {
            user_id :state.userDetails.user_id,
            first_name :  action.payload.first_name,
            last_name :  action.payload.last_name,
            phone_number : action.payload.phone_number,
            user_addresses: state.userDetails.user_addresses,
            email : state.userDetails.email,
            carts : state.userDetails.carts,
            reamind_mail :  action.payload.reamind_mail
          },
        };
    case UPDATE_ADDRESS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          user_addresses: state.userDetails.user_addresses.map((address) =>
            address.address_id === action.payload.address_id ? action.payload : address
          ),
        },
      };
    case DELETE_ADDRESS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          user_addresses: state.userDetails.user_addresses.filter(
            (address) => address.address_id !== action.payload
          ),
        },
      };
    default:
      return state;
  }
};

export default accountReducer;
