import React, { useEffect, useState } from "react";
import img1 from "../assets/img/perfume_03.webp";
import { resetCart, addToCart } from "../redux/actions/cartActions";
import { useDispatch, useSelector } from "react-redux";
import { FaCheck } from "react-icons/fa";
import { MdOutlineErrorOutline } from "react-icons/md";
import { VscBracketError } from "react-icons/vsc";
import ReactHtmlParser from "react-html-parser";
import axios from "axios";
import config from "../config";

const StatusModal = () => {
  const [status, setStatus] = useState(null);
  const [statusMessage, setStatusMessage] = useState("");
  const [statusIcon, setStatusIcon] = useState("");
  const token = useSelector((state) => state.token);
  const language = useSelector((state) => state.language.language);
  const { data } = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const { REACT_APP_BASE_URL } = process.env;
  const userDetails = useSelector((state) => state.account.userDetails);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    let paymentStatus = searchParams.get("sipay_status");

    if (paymentStatus) {
      localStorage.setItem("payment_status", paymentStatus);
    } else {
      paymentStatus = localStorage.getItem("payment_status");
    }

    if (paymentStatus) {
      const newUrl = window.location.origin + window.location.pathname;
      window.history.replaceState({}, "", newUrl);

      const obj = {
        invoice_id: localStorage.getItem("invoice_id"),
        cart_id: localStorage.getItem("cart_id"),
      };

      axios
        .post(REACT_APP_BASE_URL + "perfume-order/add-payment", obj, {
          headers: {
            xsfr: config.secretKey,
            Authorization: `Bearer ${token.tokenAuth}`,
          },
        })
        .then((res) => {
          if (paymentStatus === "0") {
            axios
              .post(
                REACT_APP_BASE_URL + "cart/find",
                {
                  user_id: userDetails.user_id,
                },
                {
                  headers: {
                    xsfr: config.secretKey,
                    Authorization: `Bearer ${token.tokenAuth}`,
                  },
                }
              )
              .then((response) => {
                const existingCarts = response.data.cart;
                if (
                  existingCarts &&
                  existingCarts.carts &&
                  existingCarts.carts.length > 0
                ) {
                  const cart_id_new = existingCarts.carts[0].cart_id;
                  const cartItems = existingCarts.carts[0].comb_atts.map(
                    (item) => ({
                      cart_id: cart_id_new,
                      comb_att_id: item.comb_att_id,
                      product_id: item.product_id,
                      quantity: item.cart_details?.quantity || "1",
                      count: item.cart_details?.quantity || "1",
                      product_price:
                        item.comb_price && !isNaN(item.comb_price.price)
                          ? parseFloat(item.comb_price.price)
                          : 0, // NaN değilse parse et, aksi halde 0 kullan
                      product_name:
                        language === "en"
                          ? item.product.product_name_en
                          : item.product.product_name,
                      product_image_url:
                        item.product.product_images &&
                        item.product.product_images.length > 0
                          ? item.product.product_images[0].image_url
                          : "",
                      product_image_attributes:
                        item.product.product_images &&
                        item.product.product_images.length > 0
                          ? language === "en"
                            ? item.product.product_images[0].image_attribute_en
                            : item.product.product_images[0].image_attribute
                          : "",
                    })
                  );
                  cartItems.forEach((item) => {
                    dispatch(addToCart(item));
                  });
                } else {
                  console.log("No carts found.");
                }
              })
              .catch((err) => {
                console.error("Error fetching cart:", err);
              });
          }

          if (paymentStatus === "1") {
            setStatus("1");
            setStatusMessage(data.status_modal_success);
            setStatusIcon(<FaCheck className="text-success" />);
            dispatch(resetCart());
          } else if (paymentStatus === "0") {
            setStatus("0");
            setStatusMessage(data.status_modal_error);
            setStatusIcon(<MdOutlineErrorOutline className="text-danger" />);
            dispatch(resetCart());
          } else {
            setStatus(null);
            setStatusMessage(data.status_modal_yanit_yok);
            setStatusIcon(<VscBracketError />);
          }
        })
        .catch((err) => {
          console.error("Error response:", err.response);
        });
    } else {
      const obj = {
        invoice_id: localStorage.getItem("invoice_id"),
      };
      axios
        .post(REACT_APP_BASE_URL + "perfume-order/add-payment", obj, {
          headers: {
            xsfr: config.secretKey,
            Authorization: `Bearer ${token.tokenAuth}`,
          },
        })
        .then((res) => {})
        .catch((err) => {
          console.error("Error response:", err.response);
        });
      setStatus("1");
      setStatusMessage(data.sipariş_tamamlandi_aciklama);
      setStatusIcon(<FaCheck className="text-success" />);
      dispatch(resetCart());
    }
  }, [
    token.tokenAuth,
    data,
    dispatch,
    REACT_APP_BASE_URL,
    userDetails.user_id,
  ]);

  const renderButton = () => {
    if (status === "1") {
      return (
        <a href={`/${language}/user/orders`}>
          <button className="button-type-one">{data.siparis_git} </button>
        </a>
      );
    } else if (status === "0") {
      return (
        <a href={`/${language}/`}>
          <button className="button-type-one">{data.anasayfa_git} </button>
        </a>
      );
    } else {
      return (
        <a href={`/${language}/`}>
          <button className="button-type-one">{data.anasayfa} </button>
        </a>
      );
    }
  };

  return (
    <div className="container-fluid main-space">
      <div className="row">
        <div className="col-lg-6 col-md-12 d-flex flex-column justify-content-center align-items-center">
          <div className="d-flex flex-column align-items-start status-main">
            {statusIcon}
            <h6> {ReactHtmlParser(statusMessage)}</h6>
            {renderButton()}
          </div>
        </div>
        <div className="col-lg-6 col-md-12 d-flex justify-content-center align-items-center mt-4 mt-lg-0">
          <img src={img1} className="status-img" alt="404" />
        </div>
      </div>
    </div>
  );
};

export default StatusModal;
