import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import UtilsHelmet from "../utils/helmet";
import { setMetaDescription } from "../utils/setMetaDescription";

const SellingContract = () => {
  const { data } = useSelector((state) => state.language);
  const language = useSelector((state) => state.language.language);
  useEffect(() => {
    setMetaDescription(
      "Haklarınız, yükümlülükleriniz ve iade şartları hakkında detaylı bilgiye buradan ulaşabilirsiniz."
    );
  }, []);
  return (
    <>
      <UtilsHelmet
        title={data.mesafeli_satis_sozlesmesi}
        canonical={`https://www.perfumeatelier.com.tr/${language}/mesafeli-satis-sozlesmesi`}
      />
      <div className="container-fluid main-space">
        <div className="col-12 d-flex align-items-start">
          <div className="">
            <h1 className="mb-3">
              <b> {data.mesafeli_satis_sozlesmesi}</b>
            </h1>
            {ReactHtmlParser(data.mesafeli_satis_inner)}
          </div>
        </div>
      </div>
    </>
  );
};

export default SellingContract;
