import React, { useState, useEffect, useRef } from "react";
import "../assets/css/homeSectionOne.css";
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import homeData from "../data/homesection.json";
import { useSelector } from "react-redux";

const HomeSectionOne = () => {
  const [items, setItems] = useState([]);
  const language = useSelector((state) => state.language.language);
  const { data } = useSelector((state) => state.language);

  useEffect(() => {
    setItems(homeData.items);
  }, []);

  useEffect(() => {
    const nextDom = nextRef.current;
    const prevDom = prevRef.current;

    const showNextSlider = () => showSlider("next");
    const showPrevSlider = () => showSlider("prev");

    let runNextAuto = setTimeout(showNextSlider);

    nextDom.addEventListener("click", showNextSlider);
    prevDom.addEventListener("click", showPrevSlider);

    return () => {
      clearTimeout(runNextAuto);
      nextDom.removeEventListener("click", showNextSlider);
      prevDom.removeEventListener("click", showPrevSlider);
    };
  }, []);

  const nextRef = useRef(null);
  const prevRef = useRef(null);
  const carouselRef = useRef(null);
  const SliderRef = useRef(null);
  const thumbnailBorderRef = useRef(null);
  const timeRef = useRef(null);

  const timeRunning = 1000;

  let runTimeOut;

  const showSlider = (type) => {
    const SliderItemsDom = SliderRef.current.querySelectorAll(
      ".carousel .list .item"
    );
    const thumbnailItemsDom =
      thumbnailBorderRef.current.querySelectorAll(".item");

    if (SliderItemsDom.length === 0 || thumbnailItemsDom.length === 0) {
      return;
    }

    if (type === "next") {
      SliderRef.current.appendChild(SliderItemsDom[0]);
      thumbnailBorderRef.current.appendChild(thumbnailItemsDom[0]);
      carouselRef.current.classList.add("next");
    } else {
      SliderRef.current.prepend(SliderItemsDom[SliderItemsDom.length - 1]);
      thumbnailBorderRef.current.prepend(
        thumbnailItemsDom[thumbnailItemsDom.length - 1]
      );
      carouselRef.current.classList.add("prev");
    }
    clearTimeout(runTimeOut);
    runTimeOut = setTimeout(() => {
      if (carouselRef.current) {
        carouselRef.current.classList.remove("next");
        carouselRef.current.classList.remove("prev");
      }
    }, timeRunning);
  };

  const handleThumbnailClick = (index) => {
    const thumbnailItemsDom =
      thumbnailBorderRef.current.querySelectorAll(".item");

    const currentIndex = Array.from(thumbnailItemsDom).findIndex(
      (item, i) => i === index
    );

    if (currentIndex === -1) {
      return;
    }

    showSlider("next");
  };

  return (
    <div className="home-section-one-main">
      <div className="carousel" ref={carouselRef}>
        <div className="list" ref={SliderRef}>
          {items.map((item, index) => (
            <div className="item" key={index}>
              <img src={item.imgSrc} alt={item.title} />
              <div className="content">
                <div className="author">{item.author}</div>
                <div className="title">
                  {language == "en" ? item.title_en : item.title}
                </div>
                <div className="des">
                  {language == "en" ? item.description_en : item.description}
                </div>
                <div className="buttons">
                  <a
                    href={
                      language == "en" ? item.buttonLink_en : item.buttonLink
                    }
                  >
                    <button className="button-type-five mt-5">
                      {data.kesfet}
                    </button>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="thumbnail" ref={thumbnailBorderRef}>
          {items.map((item, index) => (
            <div
              className="item"
              key={index}
              onClick={() => handleThumbnailClick(index)}
            >
              <img
                src={item.imgSrc}
                alt={language == "en" ? item.title_en : item.title}
              />
              <div className="content">
                <div className="title">
                  {language == "en" ? item.title_en : item.title}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="arrows">
          <button id="prev" aria-label="prev slider" ref={prevRef}>
            <MdNavigateBefore />
          </button>
          <button id="next" aria-label="next slider" ref={nextRef}>
            <MdNavigateNext />
          </button>
        </div>
        <div className="time" ref={timeRef} />
      </div>
    </div>
  );
};

export default HomeSectionOne;
