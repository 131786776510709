export const SET_LANGUAGE_DATA = 'SET_LANGUAGE_DATA';
export const SET_CURRENT_LANGUAGE = 'SET_CURRENT_LANGUAGE';

export const setLanguageData = (data) => ({
  type: SET_LANGUAGE_DATA,
  payload: data,
});

export const setCurrentLanguage = (language) => ({
  type: SET_CURRENT_LANGUAGE,
  payload: language,
  
});
