export const FETCH_BEST_SUCCESS = "FETCH_BEST_SUCCESS";

const fetchBestSellerSuccess = (bestSeller) => ({
    type: FETCH_BEST_SUCCESS,
    payload: bestSeller,
});


export const fetchBestSeller= (bestSeller) => {
    return (dispatch) => {
        dispatch(fetchBestSellerSuccess(bestSeller));
    };
};
