export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';


export const loginSuccess = () => {
  return {
    type: LOGIN_SUCCESS
  };
};

export const logoutSuccess = () => {
  return {
    type: LOGOUT_SUCCESS
  };
};
export const clearUserDetails = () => {
  return {
    type: 'CLEAR_USER_DETAILS'
  };
};