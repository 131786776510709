import React from 'react';
import { Navigate } from 'react-router-dom';
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useAuth from './helpers/useAuth';
import Loading from './wrappers/Loading';
import { useSelector } from "react-redux";

const PrivateRoute = ({ children }) => {
  const { loading, isValidToken } = useAuth();
  const language = useSelector(state => state.language.language);
  const { data } = useSelector((state) => state.language);

  if (loading) {
    return <div ><Loading/></div>;
  }

  if (!isValidToken) {
    toast.error(data.oturum_suresi);
    return <Navigate to={`/${language}/`} />;
  }

  return (
    <>
      {children}
    </>
  );
};

export default PrivateRoute;
