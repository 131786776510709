import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import Modal from "react-modal";
import { setUserUpdateDetails } from "../redux/actions/accountActions";
import config from "../config";
const { REACT_APP_BASE_URL } = process.env;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -40%)",
    borderRadius: "10px",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)",
    padding: "20px",
    width: "40%",
    maxHeight: "80vh",
    overflowY: "auto",
    backgroundColor: "white",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

if (window.matchMedia("(max-width: 768px)").matches) {
  customStyles.content.width = "92%";
} else if (
  window.matchMedia("(min-width: 769px) and (max-width: 1024px)").matches
) {
  customStyles.content.width = "75%";
}

const AccountDetail = () => {
  const userDetails = useSelector((state) => state.account.userDetails);
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.language);

  const [reamind_mail, setreamind_mail] = useState(userDetails?.reamind_mail || null);
  const [first_name, setFirstName] = useState(userDetails?.first_name|| null);
  const [last_name, setLastName] = useState(userDetails?.last_name||null);
  const [phone_number, setPhoneNumber] = useState(userDetails?.phone_number ||null);

  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

  const onUpdate = async (e) => {
    e.preventDefault();
    const obj = {
      first_name,
      last_name,
      phone_number,
      reamind_mail,
      user_id: userDetails.user_id,
      email: userDetails.email,
    };

    if (!first_name || !last_name || !phone_number) {
      toast.error(data.bos_alan_var);
    } else {
      await axios
        .post(REACT_APP_BASE_URL + "users/update", obj, {
          headers: {
            xsfr: config.secretKey,
            Authorization: `Bearer ${token.tokenAuth}`,
          },
        })
        .then((res) => {
          dispatch(setUserUpdateDetails(obj));
          toast.success(data.kullanici_bilgi_guncellendi);
        })
        .catch((err) => {
          toast.error(data.kullanici_yuklerken_hata_olustu);
        });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "currentPassword") {
      setCurrentPassword(value);
    } else if (name === "newPassword") {
      setNewPassword(value);
    } else if (name === "newPasswordConfirm") {
      setNewPasswordConfirm(value);
    }
  };

  const handleEditClick = () => {
    setEditModalIsOpen(true);
  };

  const validatePassword = (password) => {
    const regex = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@#$%^&*(),.?":{}|<>]{6,}$/;
    return regex.test(password);
  };
  
  

  const handleEditPassword = async () => {
    if (newPassword !== newPasswordConfirm) {
      toast.error(data.yeni_sifre_uyusmuyor);
      return;
    }

    if (!validatePassword(newPassword)) {
      toast.error(data.sifre_gereklilik);
      return;
    }

    const payload = {
      user_id: userDetails.user_id,
      current_password: currentPassword,
      new_password: newPassword,
    };

    try {
      const response = await axios.post(
        "https://servers3.perfumeatelier.com/users/password-change",
        payload,
        {
          headers: {
            xsfr: config.secretKey,
            Authorization: `Bearer ${token.tokenAuth}`,
          },
        }
      );

      toast.success(data.sifre_degistirildi);
      setEditModalIsOpen(false);
    } catch (error) {
      toast.error(data.sifre_hata_olustu);
    }
  };
  return (
    <div className="account-details-main">
      <h2>
        <b>{data.hesap_detaylari} </b>
      </h2>
      {userDetails ? (
        <div className="col-lg-5 col-12">
          <div className="d-grid">
            <label className="mt-2">{data.e_posta} </label>
            <input type="text" value={userDetails.email} disabled readOnly />
            <label className="mt-2">{data.isim} </label>
            <input
              type="text"
              value={first_name}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <label className="mt-2">{data.soyisim} </label>
            <input
              type="text"
              value={last_name}
              onChange={(e) => setLastName(e.target.value)}
            />
            <label className="mt-2">{data.telefon} </label>
            <input
              type="text"
              value={phone_number}
              onChange={(e) => setPhoneNumber(e.target.value)}
              maxLength={13}
            />

            <span className="text-gray mt-2" onClick={() => handleEditClick()}>
              {data.sifre_degistir}
            </span>
            <div className="mt-2 d-flex align-items-start gap-2">
              <input
                className="mt-1"
                type="checkbox"
                checked={reamind_mail}
                onChange={(e) => setreamind_mail(e.target.checked)}
              />
              <p>{data.hesap_detaylari_baslik}</p>
            </div>
            <Modal
              isOpen={editModalIsOpen}
              onRequestClose={() => setEditModalIsOpen(false)}
              style={customStyles}
              contentLabel="Edit Address Modal"
            >
              <h4>{data.sifre_degistir} </h4>

              <div className="d-grid adres-edit">
                <input
                  className="mb-2"
                  type="password"
                  name="currentPassword"
                  placeholder={data.mevcut_sifre}
                  onChange={handleInputChange}
                />
                <input
                  className="mb-2"
                  type="password"
                  name="newPassword"
                  placeholder={data.yeni_sifre}
                  onChange={handleInputChange}
                />
                <input
                  className="mb-2"
                  type="password"
                  name="newPasswordConfirm"
                  placeholder={data.yeni_sifreyi_tekrarla}
                  onChange={handleInputChange}
                />
              </div>

              <button
                className="button-type-one mt-3"
                onClick={handleEditPassword}
              >
                {data.sifre_degistir}
              </button>
            </Modal>
          </div>
          <button className="button-type-one" onClick={(e) => onUpdate(e)}>
            {data.kaydet}
          </button>
        </div>
      ) : (
        <p>{data.kullanici_yukleniyor} </p>
      )}
    </div>
  );
};

export default AccountDetail;
