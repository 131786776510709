import { SET_LANGUAGE_DATA, SET_CURRENT_LANGUAGE } from '../actions/languageActions';

const initialState = {
  language: 'en',
  data: {},
};

const languageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LANGUAGE_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case SET_CURRENT_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    default:
      return state;
  }
};

export default languageReducer;
