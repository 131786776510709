import { useEffect } from "react";

function useStorageListener() {
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "taskCompleted") {
        window.location.reload();
      }
    };
    
    window.addEventListener("storage", handleStorageChange);
    
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
}

export default useStorageListener;
