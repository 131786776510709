export const ADD_TO_CART = 'cart/ADD_TO_CART';
export const REMOVE_FROM_CART = "cart/REMOVE_FROM_CART";
export const GET_CART_TOTAL ="cart/GET_CART_TOTAL";
export const INCREASE_COUNTER = 'cart/INCREASE_COUNTER';
export const DECREASE_COUNTER = 'cart/DECREASE_COUNTER';
export const ADD_TO_FAVORITES = 'ADD_TO_FAVORITES';
export const REMOVE_FROM_FAVORITES ='REMOVE_FROM_FAVORITES';
export const RESET_CART = 'cart/RESET_CART';

export const addToCart = (product,oldList) => ({
    type: ADD_TO_CART,
    payload : product,
    oldList : oldList
    
})

export const removeFromCart = (product) => ({
    type: REMOVE_FROM_CART,
    payload : product
})

export const resetCart = () => ({
  type: RESET_CART,
});
export const getCartTotal = () => ({
    type: GET_CART_TOTAL,
})


export const increaseCounter = (index) => {
    return (dispatch) => {
      dispatch({
        type: INCREASE_COUNTER,
        payload: index
      });
      dispatch(getCartTotal());
    };
  };
  

export const decreaseCounter = (index) => {
  return (dispatch) => {
    dispatch({
      type: DECREASE_COUNTER,
      payload: index
    });
    dispatch(getCartTotal());
  };
};

export const addToFavorites = (product) => ({
  type: ADD_TO_FAVORITES,
  payload:product
});

export const removeFromFavorites = (product) => ({
  type: REMOVE_FROM_FAVORITES,
  payload:product
});