import React, { useState, useEffect } from "react";
import "../assets/css/login.css";
import { IoIosArrowBack } from "react-icons/io";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { setToken } from "../redux/actions/tokenActions";
import { setUserDetails } from "../redux/actions/accountActions";
import { useDispatch, connect } from "react-redux";
import { loginSuccess } from "../redux/actions/authActions";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { addToCart, resetCart } from "../redux/actions/cartActions";
import config from "../config";
import { BsEyeSlash } from "react-icons/bs";
import { useSelector } from "react-redux";
import UtilsHelmet from "../utils/helmet";
import useStorageListener from "../utils/useStorageListener";
import { setMetaDescription } from "../utils/setMetaDescription";
import { validateEmail, validatePassword} from "../utils/validation";

const { REACT_APP_BASE_URL } = process.env;

const LoginComponent = ({ cartItems }) => {
  useStorageListener();

  const [showLogin, setShowLogin] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmShowPassword, setConfirmShowPassword] = useState(false);
  const [phone_number, setPhoneNumber] = useState("");
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const language = useSelector((state) => state.language.language);
  const { data } = useSelector((state) => state.language);
  const [passwordValid, setPasswordValid] = useState(false);

  useEffect(() => {
    setMetaDescription(
      "Siparişlerinizi takip edebilmek veya daha fazlası için giriş yapın ya da hesap oluşturun."
    );
  }, []);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmShowPassword(!confirmShowPassword);
  };



  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleLogin = () => {
    setShowLogin(!showLogin);
  };

  const login = async () => {
    if (!email || !password) {
      toast.error(data.tum_alanlari_doldur);
      return;
    }

    if (!validateEmail(email)) {
      toast.error(data.gecerli_e_posta);
      return;
    }

    try {
      const response = await axios.post(
        REACT_APP_BASE_URL + "users/login",
        {
          email: email,
          password: password,
          carts: cartItems,
        },
        {
          headers: {
            xsfr: config.secretKey,
          },
        }
      );
      let userDetails = response.data.user;

      dispatch(setToken(response.data.token));
      dispatch(setUserDetails(userDetails));
      dispatch(loginSuccess());
      toast.success(data.basariyla_giris_yapildi);
      localStorage.setItem("taskCompleted", Date.now());

      if (response.data.user.carts.length > 0) {
        dispatch(resetCart());

        response.data.user.carts[0].comb_atts.map((cart) => {
          let selectedImages = "";
          if (cart.product.product_images.length > 0) {
            selectedImages = cart.product.product_images.filter(
              (item) =>
                item.image_attribute == cart.attribute_value.value_name ||
                item.image_attribute_en == cart.attribute_value.value_name_en
            );
          }
          const obj = {
            cart_id: response.data.user.carts[0].cart_id,
            comb_att_id: cart.comb_att_id,
            product_name:
              language == "en"
                ? cart.product.product_name_en
                : cart.product.product_name,
            product_id: cart.product.product_id,
            product_price: cart.comb_price.price,
            product_category_name:
              language == "en"
                ? cart.product.categories[0].category_name_en
                : cart.product.product_name,
            product_image_url:
              selectedImages.length > 0 ? selectedImages[0].image_url : "",
            count: cart.cart_details.quantity,
            product_image_attributes:
              language == "en"
                ? cart.attribute_value.value_name_en
                : cart.attribute_value.value_name,
          };
          dispatch(addToCart(obj));
        });
      }
      setTimeout(() => {
        navigate(`/${language}/user/account-details`);
      }, 2000);
    } catch (error) {
      if (error.response && error.response.data.message === "User not found") {
        toast.error(data.kullanici_bulunamadi);
      } else if (
        error.response &&
        error.response.data.message === "Invalid password"
      ) {
        toast.error(data.sifre_eslesmedi);
      } else {
        toast.error(data.giris_hata_meyda_geldi);
      }
    }
  };

  const createAccount = async () => {
    if (!email || !password || !first_name || !last_name || !phone_number) {
      toast.error(data.tum_alanlari_doldur);
      return;
    }

    if (!validateEmail(email)) {
      toast.error(data.gecerli_e_posta);
      return;
    }

    if (password !== confirmPassword) {
      toast.error(data.sifre_eslesmiyor);
      return;
    }

    const phonePattern = /^\+(?:[0-9] ?){6,14}[0-9]$/;
    if (!phonePattern.test(phone_number)) {
      toast.error(data.gecerli_telefon);
      return;
    }

    if (!privacyChecked) {
      toast.error(data.gizlilik_oku);
      return;
    }
 
    if (!validatePassword(password)) {
      toast.error(data.sifre_gereklilik);
      return;
    }

    try {
      const response = await axios.post(
        REACT_APP_BASE_URL + "users/add",
        {
          first_name: first_name,
          last_name: last_name,
          email: email,
          password: password,
          phone_number: phone_number,
        },
        {
          headers: {
            xsfr: config.secretKey,
          },
        }
      );

      dispatch(setUserDetails(response.data));
      toast.success(data.hesap_olusturuldu);
      setTimeout(() => {
        setShowLogin(true);
      }, 1000);
    } catch (error) {
      if (
        error.response &&
        error.response.data.message ===
          "Aynı email adresi ile kullanıcı kayıtlı."
      ) {
        toast.error(data.ayni_e_posta);
      } else {
        toast.error(data.kayit_hata_meydana_geldi);
      }
    }
  };

  useEffect(() => {
    setPasswordValid(validatePassword(password));
  }, [password]);
  return (
    <div className="login-main container-fluid main-space d-flex justify-content-center align-items-center">
      <UtilsHelmet
        title={data.giris_Yap}
        canonical={`https://www.perfumeatelier.com.tr/${language}/login`}
      />
      {showLogin ? (
        <div className="col-lg-8 col-12 d-lg-flex login-first">
          <div className="col-lg-6 col-12 login-inner flex-column d-flex justify-content-center p-5">
            <h3> {data.giris_Yap}</h3>
            <p> {data.giris_yap_baslik}</p>
            <div className="d-grid">
              <label className="mt-2">E-Mail</label>
              <input
                type="text"
                placeholder={data.e_posta}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <label className="mt-2"> {data.sifre}</label>
              <input
                type="password"
                placeholder={data.sifre}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <div className="mt-2 d-flex align-items-start gap-2">
                <a href={`/${language}/sifremi-unuttum`}>
                  <span> {data.sifremi_unuttum}</span>
                </a>
              </div>
            </div>
            <button
              className="button-type-one mt-2 w-100"
              onClick={login}
              id="login_btn"
            >
              {data.giris_Yap}
            </button>
          </div>
          <div className="col-lg-6 col-12 flex-column d-flex justify-content-center p-5">
            <h3> {data.kayit_sayfasi}</h3>
            <p>{data.kayit_ol_baslik_1}</p>
            <button
              className="button-type-one"
              onClick={toggleLogin}
              id="register_btn"
            >
              {data.hesap_olustur}
            </button>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center">
          <div className="flex-column d-flex justify-content-center">
            <IoIosArrowBack onClick={toggleLogin} />
            <h4> {data.hesap_olustur}</h4>
            <p>{data.kayit_ol_baslik_1}</p>
            <div className="d-grid">
              <div className="d-lg-flex d-md-fkex gap-3 justify-content-between">
                <div>
                  <input
                    className="w-100 mb-3"
                    type="text"
                    value={first_name}
                    placeholder={data.isim}
                    required
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div>
                  <input
                    className="w-100 mb-3"
                    type="text"
                    value={last_name}
                    placeholder={data.soyisim}
                    required
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
              <input
                type="email"
                className="w-100 mb-3"
                value={email}
                placeholder={data.e_posta}
                required
                onChange={(e) => setEmail(e.target.value)}
              />
              <PhoneInput
                placeholder={data.telefon}
                value={phone_number}
                onChange={setPhoneNumber}
                defaultCountry="TR"
                className="mb-3"
                required
                maxLength="14"
              />
              <div className="password-input">
                <input
                  className="mb-1 w-100"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  placeholder={data.sifre}
                  required
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span
                  className="password-toggle"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <BsEyeSlash /> : <BsEyeSlash />}
                </span>
              </div>
              <span
                style={{ fontSize: "0.8rem" }}
                className={` ${
                  passwordValid ? "text-success mb-1" : "text-danger mb-1"
                }`}
              >
                {passwordValid
                  ? "Şifre gereklilikleri karşılanıyor."
                  : data.sifre_gereklilik}
              </span>
              <div className="password-input">
                <input
                  className="mb-3 w-100"
                  type={confirmShowPassword ? "text" : "password"}
                  value={confirmPassword}
                  placeholder={data.sifreyi_tekrarlayin}
                  required
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <span
                  className="password-toggle"
                  onClick={toggleConfirmPasswordVisibility}
                >
                  {confirmShowPassword ? <BsEyeSlash /> : <BsEyeSlash />}
                </span>
              </div>
              <div className="d-flex">
                <input
                  type="checkbox"
                  id="privacyCheckbox"
                  name="privacyCheckbox"
                  checked={privacyChecked}
                  onChange={(e) => setPrivacyChecked(e.target.checked)}
                />
                <label className="ps-2" htmlFor="privacyCheckbox">
                  <a href={`/${language}/gizlilik-guvenlik`}>
                    <b> {data.gizlilik_sözlesmesi_baslik}&nbsp;</b>
                  </a>
                  {data.gizlilik_sözlesmesi_baslik_2}
                </label>
              </div>
            </div>
            <button
              className="button-type-one mt-2 w-100"
              onClick={createAccount}
            >
              {data.hesap_olustur}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cart.cartItems,
  };
};

export default connect(mapStateToProps)(LoginComponent);
