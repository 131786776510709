import React, { useState, useEffect } from "react";
import { IoMdMail } from "react-icons/io";
import Modal from "react-modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import config from "../config";
import { useSelector } from "react-redux";
import { setMetaDescription } from "../utils/setMetaDescription";
import UtilsHelmet from "../utils/helmet";

const { REACT_APP_BASE_URL } = process.env;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -40%)",
    borderRadius: "10px",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)",
    padding: "20px",
    width: "40%",
    maxHeight: "80vh",
    overflowY: "auto",
    backgroundColor: "white",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

if (window.matchMedia("(max-width: 768px)").matches) {
  customStyles.content.width = "92%";
} else if (
  window.matchMedia("(min-width: 769px) and (max-width: 1024px)").matches
) {
  customStyles.content.width = "75%";
}

const RememberPassword = () => {
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [editCodeModalIsOpen, setEditCodeModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);
  const { data } = useSelector((state) => state.language);
  const language = useSelector((state) => state.language.language);

  useEffect(() => {
    const validatePassword = (password) => {
      const regex = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@#$%^&*(),.?":{}|<>]{6,}$/;
      return regex.test(password);
    };
    setPasswordValid(validatePassword(newPassword));
  }, [newPassword]);
  useEffect(() => {
    setMetaDescription(
      "Şifrenizi unuttuysanız bu sayfa üzerinden yeni bir şifre belirleyebilirsiniz."
    );
  }, []);
  const handleEditCodeClick = async () => {
    try {
      await axios.post(
        REACT_APP_BASE_URL + "users/forgot-verification-code",
        { email },
        {
          headers: {
            xsfr: config.secretKey,
          },
        }
      );
      toast.success(data.dogrulama_kodu_gonderildi);
      setEditCodeModalIsOpen(true);
    } catch (error) {
      toast.error(error.response.data.message);
      console.error("Error:", error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "email") {
      setEmail(value);
    } else if (name === "confirmation_code") {
      setVerificationCode(value);
    } else if (name === "newPassword") {
      setNewPassword(value);
    } else if (name === "newPasswordConfirm") {
      setNewPasswordConfirm(value);
    }
  };

  const handleEditClick = async () => {
    try {
      await axios.post(
        REACT_APP_BASE_URL + "users/forgot-code-valid",
        { email, verificationCode },
        {
          headers: {
            xsfr: config.secretKey,
          },
        }
      );
      toast.success(data.kod_dogru_sifre_degistir);
      setEditModalIsOpen(true);
      setEditCodeModalIsOpen(false);
    } catch (error) {
      toast.error(data.kod_yanlis_tekrar_dene);
      console.error("Error:", error);
    }
  };

  const handleEditPassword = async () => {
    if (newPassword !== newPasswordConfirm) {
      toast.error(data.yeni_sifre_uyusmuyor);
      return;
    }

    if (!passwordValid) {
      toast.error(data.sifre_gereklilik);
      return;
    }

    try {
      await axios.post(
        REACT_APP_BASE_URL + "users/forgot-password-change",
        { email, password: newPassword },
        {
          headers: {
            xsfr: config.secretKey,
          },
        }
      );
      toast.success(data.sifre_degistirildi);
      setEditModalIsOpen(false);
    } catch (error) {
      toast.error(data.sifre_degistilirlmedi_tekrar_dene);
      console.error("Error:", error);
    }
  };
  return (
    <>
      <UtilsHelmet
        title={data.sifremi_unuttum}
        canonical={`https://www.perfumeatelier.com.tr/${language}/sifremi-unuttum`}
      />
      <div className="col-lg-10 col-md-12 col-12 main-space container-fluid check-out-main">
        <div className="col-12 d-flex justify-content-center align-items-start">
          <div className="col-lg-9 col-12">
            <h2 className="">{data.sifremi_unuttum} </h2>
            <p className="mt-3">{data.e_posta_geldi}</p>

            <div className="input-with-icon">
              <input
                className="w-100"
                type="text"
                name="email"
                placeholder={data.e_posta}
                onChange={handleInputChange}
              />
              <IoMdMail className="icon" />
            </div>

            <div className="col-lg-3 ">
              <button
                className="button-type-one mt-3 w-100"
                onClick={handleEditCodeClick}
              >
                {data.sifremi_sifirla}
              </button>
            </div>

            <Modal
              isOpen={editCodeModalIsOpen}
              onRequestClose={() => setEditCodeModalIsOpen(false)}
              style={customStyles}
              contentLabel="Edit Address Modal"
            >
              <h5>{data.gelen_kodu_gir} </h5>

              <div className="d-grid adres-edit">
                <label>{data.onay_kodu} </label>
                <input
                  className="mb-2 mt-2"
                  type="text"
                  name="confirmation_code"
                  placeholder={data.onay_kodu}
                  onChange={handleInputChange}
                />
              </div>

              <button
                className="button-type-one mt-3"
                onClick={handleEditClick}
              >
                {data.onayla}
              </button>
            </Modal>

            <Modal
              isOpen={editModalIsOpen}
              onRequestClose={() => setEditModalIsOpen(false)}
              style={customStyles}
              contentLabel="Edit Address Modal"
            >
              <h4>{data.sifre_degistir} </h4>

              <div className="d-grid adres-edit">
                <label>{data.yeni_sifre} </label>
                <input
                  className="mb-2"
                  type="password"
                  name="newPassword"
                  placeholder={data.yeni_sifre}
                  onChange={handleInputChange}
                />
                <span
                  style={{ fontSize: "0.8rem" }}
                  className={` ${
                    passwordValid ? "text-success mb-1" : "text-danger mb-1"
                  }`}
                >
                  {passwordValid
                    ? "Şifre gereklilikleri karşılanıyor."
                    : data.sifre_gereklilik}
                </span>
                <label>{data.yeni_sifreyi_tekrarla} </label>

                <input
                  className="mb-2"
                  type="password"
                  name="newPasswordConfirm"
                  placeholder={data.yeni_sifreyi_tekrarla}
                  onChange={handleInputChange}
                />
              </div>

              <button
                className="button-type-one mt-3"
                onClick={handleEditPassword}
              >
                {data.sifre_degistir}
              </button>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default RememberPassword;
