import React, { useEffect } from "react";
import UtilsHelmet from "../utils/helmet";
import { useSelector } from "react-redux";
import { setMetaDescription } from "../utils/setMetaDescription";

const MisionComponent = () => {
  const { data } = useSelector((state) => state.language);
  const language = useSelector((state) => state.language.language);

  useEffect(() => {
    setMetaDescription(
      "Bu sayfaya ulaşmak için gösterdiğiniz ilgi için teşekkür ederiz. Anlatmayı en sevdiğim hikaye Perfume ATELİER’in hikayesidir."
    );
  }, []);
  return (
    <>
      <UtilsHelmet
        title={data.misyonumuz}
        canonical={`https://wwww.perfumeatelier.com.tr/${language}/misyonumuz`}
      />
      <div className="main-space">
        <div className="col-12 d-flex align-items-start">
          <div className="">
            <h1 className="mb-3">
              <b> {data.misyonumuz}</b>
            </h1>
            <h2> {data.misyon_baslik}</h2>
            <p>{data.misyon_aciklama_1}</p>
            <p> {data.misyon_aciklama_2}</p>
            <p> {data.misyon_aciklama_3}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default MisionComponent;
